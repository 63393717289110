import get from "lodash/get";
import gql from "graphql-tag";
import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { USER_FIELDS } from "services/Client/schemas/fragments/user";

export default function useCreateUser(values) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "user.insert.one",
    variables: {
      object: {
        ...values,
      },
    },
    update: (data, cache) => {
      cache.modify({
        fields: {
          user(existingUsers = []) {
            const newUserRef = cache.writeFragment({
              data: data.data.insert_user_one,
              fragmentName: "NewUser",
              fragment: gql`
                fragment NewUser on user {
                  ...USER_FIELDS
                }
                ${USER_FIELDS}
              `,
            });
            // const toto = existingUsers.slice(0)
            // toto.pop()
            return [newUserRef, ...existingUsers];
          },
          user_aggregate(oldData) {
            return {
              aggregate: {
                count: oldData.aggregate.count + 1,
              },
            };
          },
        },
      });
    },
    onCompleted: (results) => {
      const patientName =
        get(results, "insert_user_one.first_name") +
        " " +
        get(results, "insert_user_one.last_name");

      notification.success({
        header: patientName,
        message: "Candidate Saved"
      });
    },
  });
  return mutate;
}
