import React, { useState } from "react";

const CardSkeleton = ({}) => {
  return (
    <div>
      <div className="bg-white rounded-lg shadow-lg">
        <div
          className="bg-white object-scale-down h-48 w-full   rounded-t-lg"
          alt="card"
        />

        <div className="px-2 py-4 bg-primary-100 animate-pulse">
          <div className="flex flex-row justify-between  ">
            <p className=" text-lg leading-9 text-gray-900 font-semibold truncate"></p>

            <div
              className="inline-block h-8 w-8 rounded-full ml-2  flex justify-center items-center"
              alt=""
            ></div>
          </div>
          <div className="flex flex-row justify-end ">
            <div
              className="inline-block h-8 w-8 rounded-full ml-2  flex justify-center items-center"
              alt=""
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CardSkeleton;
