import React, { useState, useEffect } from "react";
import get from "lodash/get";
import withAlert from "services/Alert";
import View from "./view";

let recorder;

// TODO: refactor to modular approach

function Speaking({ alert, onAnswer, answers }) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    record: false,
    recordedBlob: null,
    audio_file: null,
    canRecord: true,
  });

  useEffect(() => {
    const recordPath = get(answers, "recordPath");
    if (recordPath) {
      setState((state) => ({ ...state, canRecord: false }));
    }
  }, [answers]);

  const startRecord = () => {
    return;
  };
  const stopRecord = () => {
    alert.close();
    recorder.stop();
    recorder.stream.getTracks().forEach((i) => i.stop());
    setState((state) => ({ ...state, recordedBlob: null, record: false }));
  };

  console.log(state);
  const handleUpload = async () => {
    return;
  };

  return (
    <View
      startRecord={startRecord}
      stopRecord={stopRecord}
      recordingData={state}
      handleUpload={handleUpload}
      loading={loading}
    />
  );
}

export default withAlert(Speaking);
