import { gql } from "@apollo/client";

export const BLOCK_TAG_FIELDS = gql`
  fragment BLOCK_TAG_FIELDS on block_tag {
    id
    tag {
      id
      label
    }
  }
`;
