import React from "react";
import Autosuggest from "shared/components/Autosuggest";
import { FieldArray } from "formik";
import Table from "./table";
import CSVReader2 from "./csv";
export default function View({ formik, handlers, onCancel }) {
  const {
    values: { group, groupIds, users, userFromCSV },
    errors,
  } = formik;
  const { change, blur, onUpload, setuserFromCSV } = handlers;
  return (
    <div className="grid grid-cols-3 gap-4">
      <div className="max-w-lg flex flex-col justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        {/* <Upload
          handleFile={(value) => change("file", value)}
          file={file}
          description="XLSX, XLS up to 5MB"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        {errors.users && (
          <span className="text-red-500">{errors.users}</span>
        )} */}

        <CSVReader2 setUsers={setuserFromCSV} />

        <div className="flex justify-end mt-5">
          <span className="ml-3 inline-flex rounded-md shadow-sm">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </span>
          <span className="ml-3 inline-flex rounded-md shadow-sm">
            <button
              type="button"
              onClick={() => onUpload(userFromCSV)}
              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out"
            >
              Preview
            </button>
          </span>
        </div>
      </div>
      <div className="grid col-span-2">
        <Table data={users} pageCount={users.length} />
      </div>
    </div>
  );
}
