import React from "react";
import Option from "./components/Option";
import Question from "./components/Question";
import Title from "./components/Title";
import StackedButton from "./components/StackedButton";
// import Image from "blocks/Image";

export default function View(props) {
  const { data, handler, mutations } = props;

  const { question, title, options, length, index } = data;
  const {
    handleUpdateQuestion,
    handleUpdateQuestionOption,
    handleCreateQuestion,
    handleCreateQuestionOption,
    handleDeleteQuestionOption,
    handleUpdateQuiz,
    handleDeleteQuestion,
  } = mutations;
  return (
    <div className="m-auto  p-3  bg-gray-50 rounded-lg overflow-hidden  shadow-lg">
      <div className=" flex flex-row justify-between items-center my-3">
        <Title title={title} onSave={handleUpdateQuiz} />

        <div className="flex items-center">
          <button
            type="button"
            onClick={handleCreateQuestion}
            className="inline-flex mr-1 items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
          >
            Create Question
          </button>
        </div>
      </div>
      <div className="bg-white m-auto rounded overflow-hidden">
        <div className="flex flex-col my-6 mx-3 max-w-full">
          <div className="flex justify-end items-center">
            <div className="flex items-center justify-center">
              <StackedButton
                first={index + 1 === 1}
                last={index + 1 === length}
                setIndex={handler}
                onDelete={handleDeleteQuestion}
                hasMath={question.hasMath}
                ToggleMathMode={() =>
                  handleUpdateQuestion(question.id, {
                    hasMath: !question.hasMath,
                  })
                }
              />
            </div>
          </div>

          <div className="overflow-auto">
            <Question
              question={question}
              index={index}
              onSave={handleUpdateQuestion}
            />
          </div>
        </div>
        <hr />
        <div className="mt-6">
          {options.map((option, index) => {
            return (
              <Option
                key={option.id}
                isNew={false}
                option={option}
                onDelete={handleDeleteQuestionOption}
                onSave={handleUpdateQuestionOption}
              />
            );
          })}
          <Option
            key={"new"}
            isNew={true}
            option={{
              label: "",
              rightAnswer: false,
              hasMath: false,
              order: "+",
            }}
            onDelete={() => console.log("empty")}
            onSave={handleCreateQuestionOption}
          />
        </div>
      </div>
    </div>
  );
}
