import { gql } from "@apollo/client";

export const TEXT_FIELDS = gql`
  fragment TEXT_FIELDS on text {
    id
    title
    content
    createdAt
  }
`;
