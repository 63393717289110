import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// import Image from "blocks/Image";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function View(props) {
  const { data } = props;

  const { questions, options } = data;

  return (
    <div className="m-auto  p-3  bg-gray-50 rounded-lg overflow-hidden  shadow-lg">
      <DragDropContext>
        <div className="bg-white m-auto rounded overflow-hidden p-5 flex-col space-y-2">
          <Droppable droppableId="choices">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex space-x-2"
              >
                {options.map((option, index) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id}
                    index={index}
                  >
                    {(provided) => (
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="inline-flex rounded-full items-center py-1.5 px-2.5 text-sm font-medium bg-indigo-100 text-indigo-700"
                      >
                        {option.label}
                      </span>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div>
            <ul className="divide-y divide-gray-200">
              {questions.map((question) => (
                <li key={question.id} className="py-4">
                  <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <span>{question.label}</span>
                        <div className="flex space-x-2">
                          <Droppable droppableId={question.id}>
                            {(provided) => (
                              <span
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classNames(
                                  !question.correct_answer && "w-16 h-6",
                                  "inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                                )}
                              >
                                {question.correct_answer?.label}

                                {provided.placeholder}
                              </span>
                            )}
                          </Droppable>
                        </div>
                      </div>
                      {/* <p className="text-sm text-gray-500">
                Deployed {activityItem.project} ({activityItem.commit}{" "}
                in master) to {activityItem.environment}
              </p> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </DragDropContext>
      {/* <div className=" flex justify-center mt-4  ">
        <button
          type="button"
          className=" uppercase mb-4 items-center px-4 py-2 border border-black hover:border-primary-500 text-sm leading-5 font-medium  rounded-lg  text-black hover:text-white  bg-transparent hover:bg-primary-500  transition ease-in-out duration-150"
          onClick={onfinish}
        >
          Check answers
        </button>
      </div> */}
    </div>
  );
}
