import { useState } from "react";
import axios from "axios";

import { useGcal } from "@geerdsolutions/gcal";
import { ASSETS_API, ASSETS_CDN_URL } from "env";



export default function useUploadFile() {
  const [progress, setProgress] = useState(0);
  const { getSession } = useGcal();
  const s3_service = axios.create({ baseURL: `${ASSETS_API}/api` });

  async function uploadFile({ fileName, fileType, fileSize, file }) {
    // get signed post request
    const session = await getSession();
    const token = session.getAccessToken().getJwtToken();
    const preSignedPost = await s3_service
      .post(
        "/presign",
        {
          fileName: "elearning/" + fileName,
          fileType,
          fileSize,
          acl: "public-read",
          metadata: { app: "elearning", kind: "media_block" },
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => res.data);
    // post to aws
    // -- prepare post data
    const { fields, url } = preSignedPost;
    console.log("Uploading to : ", url);
    // -- create form data
    let formData = new FormData();
    Object.keys(fields).forEach((key) => formData.append(key, fields[key]));
    formData.append("Content-Type", fileType);
    formData.append("file", file);
    // -- post
    await axios.post(url, formData, {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        if (percentCompleted === 100) {
          setProgress(0);
        }
        // console.log("progress", percentCompleted);
      },
    });

    // -- resource url
    return `${ASSETS_CDN_URL}/${fields.key}`;
  }
  return { uploadFile, progress };
}
