import React from "react";
import get from "lodash/get";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import { useQuery, useMutation } from "services/Client";
import { useNotification } from "hooks";
import { useParams, useNavigate } from "react-router-dom";
import CreateOrEditCourse from "../shared/CreateOrEditCourse";

export default function All() {
  const { id } = useParams();
  const notification = useNotification();

  const navigate = useNavigate();
  const { data } = useQuery({
    event: "course.get.one",
    variables: {
      id,
    },
    skip: !id,
  });
  const course = get(data, "course_by_pk", {
    title: "",
    instructions: "",
  });

  const { mutate: updatecourse } = useMutation({
    event: "course.update",
    onCompleted: () => {
      notification.success({
        message: "course Saved",
      });
      navigate(-1)
    },
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        title: course.title,
        file: course.image,
      }}
      onSubmit={(values) =>
        updatecourse({
          variables: {
            _set: {
              ...values,
            },
            pk_columns: { id },
          },
        })
      }
      validationSchema={object({
        title: string().min(5, "error").required("error"),
        instructions: string().min(5, "error").required("error"),
      })}
    >
      {({ handleChange, values: { file } }) => (
        <Form>
          <CreateOrEditCourse onChange={handleChange} file={file} mode="edit" />
        </Form>
      )}
    </Formik>
  );
}
