import { gql } from "@apollo/client";
import { COURSE_FIELDS } from "./course";
export const PROGRESSION_FIELDS = gql`
  fragment PROGRESSION_FIELDS on progression {
    id
    course {
      ...COURSE_FIELDS
    }
    level {
      id
      name
      order
      image
      overview
    }
    unit {
      id
      name
    }
    lesson {
      id
    }
    activity {
      id
      name
    }
  }
  ${COURSE_FIELDS}
`;
