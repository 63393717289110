import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { LEVEL_GET_MANY } from "services/Client/schemas/queries/level";
export default function useCreateLevel(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "level.create",
    update: ({ data: { insert_level_one } }, cache) => {
      const prevData = cache.readQuery({
        query: LEVEL_GET_MANY,
        variables: {
          where: { courseId: { _eq: id } }
        },
      });
      const data = {
        level: [insert_level_one, ...prevData.level],
      };
      cache.writeQuery({
        query: LEVEL_GET_MANY,
        data,
        variables: {
          where: { courseId: { _eq: id } }
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Level Saved",
      });
    },
  });
  return mutate;
}
