import gql from "graphql-tag";
import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { COURSE_FIELDS } from "services/Client/schemas/fragments/course";
export default function useCreatePlacementTest(values) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "course.create",
    update: (data, cache) => {
      cache.modify({
        fields: {
          course(existing = []) {
            const newRef = cache.writeFragment({
              data: data.data.insert_placement_test_one,
              fragmentName: "NewPlacementTest",
              fragment: gql`
                fragment NewPlacementTest on course {
                  ...COURSE_FIELDS
                }
                ${COURSE_FIELDS}
              `,
            });
            return [newRef, ...existing];
          },
          course_aggregate(oldData) {
            return {
              aggregate: {
                count: oldData.aggregate.count + 1,
              },
            };
          },
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Test Saved"
      });
    },
  });
  return mutate;
}
