import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import queries from "./schemas/queries";

function useQueryWrapper({ event, variables, skip }) {
  function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryParams();
  const pageIndex = parseInt(query.get("page")) || 0;
  const pageSize = parseInt(query.get("pageSize")) || 10;
  const [offsets, setOffsets] = useState({});
  // const { fetchPolicy } = usePagination({ event, page: pageIndex });
  const { fetchMore, ...rest } = useQuery(queries[event], {
    variables: { ...variables, limit: pageSize, offset: pageIndex * pageSize },
    skip,
  });

  useEffect(() => {
    if (pageIndex in offsets) return;
    fetchMore({ variables: { offset: pageIndex * pageSize } });
    setOffsets(offsets => ({ ...offsets, [pageIndex]: true }));
  }, [fetchMore, offsets, setOffsets, pageIndex, pageSize]);

  return { ...rest, pageSize };
}

useQueryWrapper.propTypes = {
  event: PropTypes.string.isRequired,
};

export default useQueryWrapper;
