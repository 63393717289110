import { useMutation } from "services/Client";
import get from "lodash/get"
import { QUESTION_OPTION_GET_MANY } from "services/Client/schemas/queries/questionOption";
export default function useCreateQuestionOption() {
  const { mutate } = useMutation({
    event: "questionOption.create",
    update: ({ data: { insert_question_option_one } }, cache) => {
      const dndId = get(insert_question_option_one, "dndId");
      const prevData = cache.readQuery({
        query: QUESTION_OPTION_GET_MANY,
        variables: {
          where: { dndId: { _eq: dndId } },
          order_by: { order: "asc" },
        },
      });

      const data = {
        question_option: [
          ...prevData.question_option,
          insert_question_option_one,
        ],
      };

      cache.writeQuery({
        query: QUESTION_OPTION_GET_MANY,
        data,
        variables: {
          where: { dndId: { _eq: dndId } },
          order_by: { order: "asc" },
        },
      });
    },
  });
  return mutate;
}
