import React, { useState } from "react";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import { useMutation } from "services/Client";
import View from "./view";
import useGetData from "./useGetData";
import useCreateProgression from "./useCreateProgression";
import EditProgression from "./editProgression";
function EditUser() {
  const { id } = useParams();
  const [progressionId, setProgressionId] = useState("");
  const { user, progressions, courses } = useGetData(id);

  const { mutate: updateUser } = useMutation({ event: "user.update" });
  const createProgression = useCreateProgression(id);

  const submit = async (updateUser, values, reset) => {
    const { coursesToSave } = values;
    if (coursesToSave.length > 0) {
      createProgression({
        variables: {
          objects: coursesToSave.map((course) => ({
            userId: id,
            courseId: course.id,
            levelId: course.levelId,
            unitId: course.unitId,
          })),
        },
      });
    }
    updateUser({
      variables: {
        _set: {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email.toLowerCase(),
        },
        pk_columns: { id },
      },
    });

    reset();
  };
  console.log(progressionId);
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          coursesToSave: [],
          course: "",
        }}
        onSubmit={(values, { resetForm }) =>
          submit(updateUser, values, resetForm)
        }
        validationSchema={object({
          first_name: string().required("error"),
          last_name: string().required("error"),
          email: string().email("error").required("error"),
        })}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <View
              courses={courses}
              progressions={progressions}
              setProgressionId={setProgressionId}
              formik={{
                values: { ...values },
              }}
              handlers={{
                change: handleChange,
                blur: handleBlur,
                resetTest: () => {},
              }}
            />
          </Form>
        )}
      </Formik>
      <EditProgression
        id={progressionId}
        setOpen={() => setProgressionId("")}
      />
    </>
  );
}

export default EditUser;
