import gql from "graphql-tag";
import { ACTIVITY_FIELDS } from "../fragments/activity";

export const ACTIVITY_CREATE = gql`
  mutation insert_activity_one($object: activity_insert_input!) {
    insert_activity_one(object: $object) {
      ...ACTIVITY_FIELDS
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const ACTIVITY_UPDATE = gql`
  mutation update_activity_by_pk(
    $pk_columns: activity_pk_columns_input!
    $_set: activity_set_input
  ) {
    update_activity_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...ACTIVITY_FIELDS
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const ACTIVITY_DELETE = gql`
  mutation delete_activity_by_pk($id: uuid!) {
    delete_activity_by_pk(id: $id) {
      ...ACTIVITY_FIELDS
    }
  }
  ${ACTIVITY_FIELDS}
`;

const activity_mutations = {
  "activity.create": ACTIVITY_CREATE,
  "activity.update": ACTIVITY_UPDATE,
  "activity.delete": ACTIVITY_DELETE,
};

export default activity_mutations;
