import React, { useState } from "react";
import get from "lodash/get";
import Table from "shared/components/Table";
import { useNavigate } from "react-router-dom";

import { useQueryPaginated, useQuery } from "services/Client";
export default function All() {
  // const [skip,setSkip]=useState()
  const [firstNameFilter, setFirstNameFilter] = useState("%%");
  const [lastNameFilter, setlastNameFilter] = useState("%%");
  const [emailFilter, setEmailFilter] = useState("%%");
  let navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first_name",
      },
      {
        Header: "Last Name",
        accessor: "last_name",
      },

      {
        Header: "Email",
        accessor: "email",
      },
    ],
    []
  );

  const { data, loading, pageSize } = useQueryPaginated({
    event: "user.get.many",
    variables: {
      //skip:skip,
      where: {
        _or: {
          first_name: { _ilike: `%${firstNameFilter}%` },
          last_name: { _ilike: `%${lastNameFilter}%` },
          email: { _ilike: `%${emailFilter}%` },
        },
      },
      order_by: { createdAt: "desc" },
    },
  });
  const { data: userCount } = useQuery({
    event: "user.get.count",
  });
  const userData = get(data, "user", []);
  const count = get(userCount, "user_aggregate.aggregate.count", []) / pageSize;

  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <Table
            onRowClick={(user) => navigate(`${user.id}`)}
            columns={columns}
            data={userData}
            loading={loading}
            pageCount={count}
            setters={{
              header_first_name: setFirstNameFilter,
              header_last_name: setlastNameFilter,
              header_email: setEmailFilter,
            }}
          />
        </div>
      </div>
    </div>
  );
}
