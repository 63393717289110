import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// import Image from "blocks/Image";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function View(props) {
  const { data } = props;

  const { questions, options } = data;

  function handleOnDragEnd(result) {}
  return (
    <div className="m-auto  p-3  bg-gray-50 rounded-lg overflow-hidden  shadow-lg">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="bg-white m-auto rounded overflow-hidden p-5 flex-col space-y-2">
          <Droppable droppableId="choices">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex space-x-2"
              >
                {options.map((option, index) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id}
                    index={index}
                  >
                    {(provided) => (
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="inline-flex rounded-full items-center py-1.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                      >
                        {option.label}
                      </span>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="flex space-x-4">
            <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              {questions.map((question) => (
                <li key={question.id} className="relative">
                  <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                    <img
                      src={question.media.path}
                      alt=""
                      className="object-cover pointer-events-none group-hover:opacity-75"
                    />
                    {/* <button
                      type="button"
                      className="absolute inset-0 focus:outline-none"
                    >
                      <span className="sr-only">
                        View details for {file.title}
                      </span>
                    </button> */}
                  </div>
                  <Droppable droppableId={question.id}>
                    {(provided) => (
                      <span
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNames(
                          !question.correct_answer && "w-16 h-6",
                          "inline-flex rounded-full items-center mt-2 py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                        )}
                      >
                        {question.correct_answer?.label}

                        {provided.placeholder}
                      </span>
                    )}
                  </Droppable>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </DragDropContext>
      {/* <div className=" flex justify-center mt-4  ">
        <button
          type="button"
          className=" uppercase mb-4 items-center px-4 py-2 border border-black hover:border-primary-500 text-sm leading-5 font-medium  rounded-lg  text-black hover:text-white  bg-transparent hover:bg-primary-500  transition ease-in-out duration-150"
          onClick={onfinish}
        >
          Check answers
        </button>
      </div> */}
    </div>
  );
}
