import React from "react";

import { useQuery, useMutation } from "services/Client";
import SmartContainer from "./components/SmartContainer";
import BlockView from "./components/BlockView";

import get from "lodash/get";
import { useParams } from "react-router-dom";
import { ACTIVITY_LAYOUT_GET_MANY } from "services/Client/schemas/queries/activity_layout";

function ActivityBuilder() {
  const { id: activityId } = useParams();

  const { data } = useQuery({
    event: "activity.layout.get.many",
    variables: {
      where: { activityId: { _eq: activityId } },
      order_by: { order: "asc" },
    },
    skip: !activityId,
  });

  const { mutate: updateActivityLayout } = useMutation({
    event: "activity.layout.update",
  });
  const updateWidth = (mutate, id, prevSize) => () => {
    const size = prevSize === "f" ? "h" : "f";
    mutate({
      variables: {
        _set: {
          size,
        },
        pk_columns: { id },
      },
    });
  };

  const { mutate: createActivityLayout } = useMutation({
    event: "activity.layout.create",
    update: ({ data: { insert_activity_layout_one } }, cache) => {
      const prevData = cache.readQuery({
        query: ACTIVITY_LAYOUT_GET_MANY,
        variables: {
          where: { activityId: { _eq: activityId } },
          order_by: { order: "asc" },
        },
      });
      const data = {
        activity_layout: [
          ...prevData.activity_layout,
          insert_activity_layout_one,
        ],
      };
      cache.writeQuery({
        query: ACTIVITY_LAYOUT_GET_MANY,
        data,
        variables: {
          where: { activityId: { _eq: activityId } },
          order_by: { order: "asc" },
        },
      });
    },
  });

  const { mutate: deleteActivityLayout } = useMutation({
    event: "activity.layout.delete",
    update: ({ data: { delete_activity_layout_by_pk } }, cache) => {
      const prevData = cache.readQuery({
        query: ACTIVITY_LAYOUT_GET_MANY,
        variables: {
          where: { activityId: { _eq: activityId } },
          order_by: { order: "asc" },
        },
      });

      const newData = prevData.activity_layout.filter(
        (activity_layout) =>
          activity_layout.id !== delete_activity_layout_by_pk.id
      );
      const data = {
        activity_layout: [...newData],
      };
      cache.writeQuery({
        query: ACTIVITY_LAYOUT_GET_MANY,
        data,
        variables: {
          where: { activityId: { _eq: activityId } },
          order_by: { order: "asc" },
        },
      });
    },
  });
  const layout = get(data, "activity_layout", []);
  const order = get(layout[layout.length - 1], "order", 0) + 1;
  const processLayout = (layout) => {
    let batch = [];

    let all_batches = [];
    for (let i = 0; i < layout.length; i++) {
      let item = layout[i];

      if (item.size != "f") {
        batch.push(item);
      } else {
        all_batches.push(["batch", batch]);
        batch = [];
        all_batches.push(["splitter", item]);
      }
      if (i == layout.length - 1 && batch.length > 0) {
        all_batches.push(["batch", batch]);
      }
    }

    return all_batches;
  };

  console.log("processed layout ", processLayout(layout));

  return (
    <div className="flex flex-wrap h-full">
      {processLayout(layout).map((item_, index) => {
        // get type
        const type = item_[0];
        if (type == "batch") {
          let batch = item_[1];
          let pair_batch = [];
          let unpair_batch = [];
          // split batch into list of pair and list of single
          batch.forEach((x, idx) =>
            (idx % 2 ? pair_batch : unpair_batch).push(x)
          );

          //console.log("pair_batch", index, pair_batch.length);
          //console.log("unpair_batch", index, unpair_batch.length);
          return (
            <div className="flex flex-row-reverse w-full">
              <div className="w-1/2">
                {pair_batch.map((item) => (
                  <BlockView
                    key={item.id}
                    blockId={item.blockId}
                    handlers={{
                      updateWidth: updateWidth(
                        updateActivityLayout,
                        item.id,
                        item.size
                      ),
                      deleteBlock: () =>
                        deleteActivityLayout({
                          variables: { id: item.id },
                        }),
                    }}
                    isFull={item.size === "f"}
                    isStatic={item.isStatic}
                    type={item.type}
                  />
                ))}
              </div>
              <div className="w-1/2">
                {unpair_batch.map((item) => (
                  <BlockView
                    key={item.id}
                    blockId={item.blockId}
                    handlers={{
                      updateWidth: updateWidth(
                        updateActivityLayout,
                        item.id,
                        item.size
                      ),
                      deleteBlock: () =>
                        deleteActivityLayout({
                          variables: { id: item.id },
                        }),
                    }}
                    isFull={item.size === "f"}
                    isStatic={item.isStatic}
                    type={item.type}
                  />
                ))}
              </div>
            </div>
          );
        }
        if (type === "splitter") {
          let item = item_[1];
          return (
            <BlockView
              key={item.id}
              blockId={item.blockId}
              handlers={{
                updateWidth: updateWidth(
                  updateActivityLayout,
                  item.id,
                  item.size
                ),
                deleteBlock: () =>
                  deleteActivityLayout({
                    variables: { id: item.id },
                  }),
              }}
              isFull={item.size === "f"}
              isStatic={item.isStatic}
              type={item.type}
            />
          );
        }
      })}

      {/**layout.map((item) => (
        <BlockView
          key={item.id}
          blockId={item.blockId}
          handlers={{
            updateWidth: updateWidth(updateActivityLayout, item.id, item.size),
            deleteBlock: () =>
              deleteActivityLayout({
                variables: { id: item.id },
              }),
          }}
          isFull={item.size === "f"}
          isStatic={item.isStatic}
          type={item.type}
        />
        ))**/}

      <SmartContainer
        key={"newBlock"}
        activityId={activityId}
        order={order}
        insertStaticBlock={(size, type) =>
          createActivityLayout({
            variables: {
              object: {
                order,
                activityId,
                isStatic: true,
                type,
                size,
              },
            },
          })
        }
      />
    </div>
  );
}

export default ActivityBuilder;
