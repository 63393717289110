import { gql } from "@apollo/client";

export const COURSE_FIELDS = gql`
  fragment COURSE_FIELDS on course {
    id
    title
    image
    levels(order_by: { order: asc }) {
      id
      name
      units(order_by: { order: asc }) {
        id
        name
      }
    }
  }
`;
