import gql from "graphql-tag";
import { QUESTION_OPTION_FIELDS } from "../fragments/question_option";

export const QUESTION_OPTION_GET_MANY = gql`
  query question_option(
    $where: question_option_bool_exp
    $order_by: [question_option_order_by!]
  ) {
    question_option(
      where: $where
      order_by: $order_by
    ) {
      ...QUESTION_OPTION_FIELDS
    }
  }
  ${QUESTION_OPTION_FIELDS}
`;

export const QUESTION_OPTION_GET_ONE = gql`
  query question_option_by_pk($id: uuid!) {
    question_option_by_pk(id: $id) {
      ...QUESTION_OPTION_FIELDS
    }
  }
  ${QUESTION_OPTION_FIELDS}
`;

const question_option_queries = {
  "questionOption.get.one": QUESTION_OPTION_GET_ONE,
  "questionOption.get.many": QUESTION_OPTION_GET_MANY,
};

export default question_option_queries;
