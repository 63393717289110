import React from "react";
import get from "lodash/get";
import { object, string, number } from "yup";
import { Formik, Form } from "formik";
import { useQuery, useMutation } from "services/Client";
import { useParams, useNavigate } from "react-router-dom";
import { useNotification } from "hooks";

import CreateOrEditLesson from "../shared/CreateOrEditLesson";
export default function EditLesson() {
  const { id } = useParams();
  const notification = useNotification();

  let navigate = useNavigate();
  const { data } = useQuery({
    event: "lesson.get.one",
    variables: {
      id,
    },
    skip: !id,
  });
  const lesson = get(data, "lesson_by_pk", {
    name: "",
    order: 0,
  });

  const { mutate: updateLevel } = useMutation({
    event: "lesson.update",
    onCompleted: () => {
      notification.success({
        message: "lesson Saved",
      });
      navigate(-1);
    },
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: lesson.name,
        order: lesson.order,
      }}
      onSubmit={(values) =>
        updateLevel({
          variables: {
            _set: {
              ...values,
            },
            pk_columns: { id },
          },
        })
      }
      validationSchema={object({
        name: string().min(5, "error").required("error"),
        order: number().required("error").positive().integer(),
      })}
    >
      <Form>
        <CreateOrEditLesson onCancel={() => navigate(-1)} />
      </Form>
    </Formik>
  );
}
