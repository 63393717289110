import { gql } from "@apollo/client";
export const BLOCK_LIST_FIELDS = gql`
  fragment BLOCK_LIST_FIELDS on block_list_item {
    id
    name
    type
    fillBlankId
    dndId
    quizId
    textId
    mediaId
    media {
      id
      type
      path
    }
    block_tags {
      tag {
        label
        id
      }
    }
  }
`;
