/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from "react";
import get from "lodash/get";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { PencilAltIcon } from "@heroicons/react/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { useQuery, useMutation } from "services/Client";
import { useFormik } from "formik";
import { useNotification } from "hooks";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MyListBox = ({ selected, setSelected, data, name }) => (
  <Listbox value={selected} onChange={(v) => setSelected(name, v)}>
    {({ open }) => (
      <>
        <div className="mt-1 relative">
          <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              {data.map((item) => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-indigo-600" : "text-gray-900",
                      "cursor-default select-none relative py-2 pl-3 pr-9"
                    )
                  }
                  value={item}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={classNames(
                          selected ? "font-semibold" : "font-normal",
                          "block truncate"
                        )}
                      >
                        {item.name}
                      </span>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-indigo-600",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </>
    )}
  </Listbox>
);

export default function EditProgression({ id, setOpen }) {
  const cancelButtonRef = useRef(null);
  const notification = useNotification();
  const { data, loading } = useQuery({
    event: "progression.get.one",
    variables: {
      id,
    },
    skip: !id,
  });

  const progression = get(data, "progression_by_pk");
  const course_title = get(progression, "course.title");
  const levels = get(progression, "course.levels", []);
  const currentLevel = get(progression, "level", { name: "", id: "" });
  const currentUnit = get(progression, "unit", { name: "", id: "" });

  const { mutate } = useMutation({
    event: "progression.update",
    onCompleted: () => {
      notification.success({ message: "Progression Updated" });
      setOpen()
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      level: currentLevel,
      unit: currentUnit,
    },
    onSubmit: (values) => {
      mutate({
        variables: {
          _set: {
            levelId: values.level.id,
            unitId: values.unit.id,
          },
          pk_columns: { id },
        },
      });
    },
  });

  const { level, unit } = formik.values;

  const { data: unitsData } = useQuery({
    event: "unit.get.many",
    variables: {
      where: { levelId: { _eq: level.id } },
    },
    skip: !level.id,
  });

  const units = get(unitsData, "unit", []);

  return (
    <Transition.Root show={!!id} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilAltIcon
                      className="h-6 w-6 text-blue-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {course_title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {" "}
                      <div>
                        <div>
                          <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
                            Change the progression of the student.
                          </p>
                        </div>
                        {!loading && (
                          <div className="mt-6 sm:mt-5">
                            <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Level
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
                                  <MyListBox
                                    selected={level}
                                    setSelected={formik.setFieldValue}
                                    data={levels}
                                    name="level"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                              <label
                                htmlFor="first_name"
                                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                              >
                                Unit
                              </label>
                              <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
                                  <MyListBox
                                    selected={unit}
                                    setSelected={formik.setFieldValue}
                                    data={units}
                                    name="unit"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={formik.handleSubmit}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={setOpen}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
