import React from "react";
import omit from "lodash/omit";
export default class FieldStyle extends React.Component {
  constructor(props) {
    super(props);

    // init counter
    this.count = 0;

    // init state
    this.state = {
      value: props.value,
    };
  }
  componentDidMount() {
    this.ref && this.ref.focus();
  }

  render() {
    const { autoFocus, ...rest } = this.props;

    // auto focus
    const ref = autoFocus
      ? (ref) => {
          this.ref = ref;
        }
      : null;
    return (
      <input
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        ref={ref}
        type="text"
        {...omit(rest, ["onSave"])}
      />
    );
  }
}
