import EditQuiz from "screens/Blocks/EditQuiz";
import EditText from "screens/Blocks/EditText";
import EditFillBlank from "screens/Blocks/EditFillBlank";
import EditDnd from "screens/Blocks/EditDnd";

import List from "./List";
var indexRoutes = [
  {
    path: "attach/:activityId/:size/:order",
    name: "Attach Block",
    invisible: true,
    component: List,
    layout: "/admin",
  },
  {
    path: "quiz/:id",
    name: "Edit Quiz",
    invisible: true,
    component: EditQuiz,
    layout: "/admin",
  },
  {
    path: "text",
    exact: true,
    name: "Text",
    component: Text,
    layout: "/admin",
  },
  {
    path: "text/:id",
    invisible: true,
    exact: true,
    name: "Edit Test",
    component: EditText,
    layout: "/admin",
  },
  {
    path: "fillblank/:id",
    name: "Edit Fill Blank",
    invisible: true,
    component: EditFillBlank,
    layout: "/admin",
  },
  {
    path: "dnd/:id",
    name: "Edit Drag and Drop",
    invisible: true,
    component: EditDnd,
    layout: "/admin",
  },
];

export default indexRoutes;
