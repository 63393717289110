import React from "react";
import AudioPreview from "./AudioPreview";
import UploadAudio from "./UploadAudio";
import RecordButton from "./RecordButton";

import ClipLoader from "react-spinners/MoonLoader";
export default function View({
  recordingData,
  startRecord,
  stopRecord,
  loading,
  handleUpload,
}) {
  // console.log(bar);
  const { record: isRecord, recordedBlob, canRecord } = recordingData;
  const stopIconPath =
    "M16,4.995v9.808C16,15.464,15.464,16,14.804,16H4.997C4.446,16,4,15.554,4,15.003V5.196C4,4.536,4.536,4,5.196,4h9.808C15.554,4,16,4.446,16,4.995z";
  const startIconPath =
    "M9 18v-1.06A8 8 0 0 1 2 9h2a6 6 0 1 0 12 0h2a8 8 0 0 1-7 7.94V18h3v2H6v-2h3zM6 4a4 4 0 1 1 8 0v5a4 4 0 1 1-8 0V4z";

  return (
    <div className="rounded-md shadow-lg">
      <div className="px-4 py-5 border-b border-gray-100 sm:px-6">
        <div className="-ml-4 -mt-4 flex justify-between items-center sm:flex-no-wrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Speaking
            </h3>
            <p className="mt-1 text-sm leading-5 text-gray-500">
              Press the microphone to record your voice
            </p>
          </div>
        </div>
      </div>
      <hr />

      <div className="flex justify-evenly items-center p-5">
        <div className="">
          <AudioPreview src={recordedBlob} />
        </div>
        <div>
          <RecordButton
            handleRecord={isRecord ? stopRecord : startRecord}
            canRecord={canRecord}
            iconPath={isRecord ? stopIconPath : startIconPath}
          />
        </div>
        <div className="flex justify-center">
          {loading && (
            <div className="absolute">
              <ClipLoader size={33} color={"#4299e1"} />
            </div>
          )}
          <UploadAudio
            src={recordedBlob}
            onClick={handleUpload}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}
