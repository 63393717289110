import gql from "graphql-tag";

import { PROGRESSION_FIELDS } from "../fragments/progression";
export const PROGRESSION_CREATE = gql`
  mutation insert_progression($objects: [progression_insert_input!]!) {
    insert_progression(objects: $objects) {
      returning {
        ...PROGRESSION_FIELDS
      }
    }
  }
  ${PROGRESSION_FIELDS}
`;

export const PROGRESSION_UPDATE = gql`
  mutation update_progression_by_pk(
    $pk_columns: progression_pk_columns_input!
    $_set: progression_set_input
  ) {
    update_progression_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...PROGRESSION_FIELDS
    }
  }
  ${PROGRESSION_FIELDS}
`;

const progression_mutations = {
  "progression.create": PROGRESSION_CREATE,
  "progression.update": PROGRESSION_UPDATE
};

export default progression_mutations;
