import gql from "graphql-tag";
import { SECTION_FIELDS } from "../fragments/section";
export const SECTION_GET_ONE = gql`
  query section_by_pk($id: uuid!) {
    section_by_pk(id: $id) {
      ...SECTION_FIELDS
    }
  }
  ${SECTION_FIELDS}
`;

export const SECTION_GET_MANY = gql`
  query section($where: section_bool_exp, $order_by: [section_order_by!]) {
    section(where: $where, order_by: $order_by) {
      ...SECTION_FIELDS
    }
  }
  ${SECTION_FIELDS}
`;

const section_queries = {
  "section.get.one": SECTION_GET_ONE,
  "section.get.many": SECTION_GET_MANY,
};

export default section_queries;
