import React from "react";
import get from "lodash/get";
import { useQuery, useMutation } from "services/Client";
import { useNavigate, useParams } from "react-router-dom";
import { useNotification } from "hooks";

import List from "./View";

function AllUnits() {
  const { levelId } = useParams();
  const notification = useNotification
  const { data } = useQuery({
    event: "unit.get.many",
    variables: {
      where: { levelId: { _eq: levelId } },
    },
  });

  const dataList = get(data, "unit", [])
    .slice(0)
    .sort((a, b) => a.order - b.order);

  const { mutate: deleteTest } = useMutation({
    event: "level.delete",
  });
  let navigate = useNavigate();

  const removeTest = async (mutate, id) => {
    mutate({ variables: { where: { id } } })
      .then((res) => {
        if (get(res, "data.deletelevel.id")) {
          notification.success("Delete Successful");
        } else {
          notification.error("Error");
        }
      })
      .catch((e) => {});
  };
  return (
    <List
      data={dataList}
      handleCreate={() => navigate("new")}
      handleDelete={(id) => removeTest(deleteTest, id)}
    />
  );
}

export default AllUnits
