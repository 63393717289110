import gql from "graphql-tag";
import { QUESTION_FIELDS } from "../fragments/question";
export const QUESTION_GET_MANY = gql`
  query question(
    $where: question_bool_exp
    $order_by: [question_order_by!]
  ) {
    question(
      where: $where
      order_by: $order_by
    ) {
      ...QUESTION_FIELDS
    }
  }
  ${QUESTION_FIELDS}
`;

const question_queries = {
  "question.get.many": QUESTION_GET_MANY,
};

export default question_queries;
