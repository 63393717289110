import AllUsers from "screens/Users/All";
import UploadUsers from "screens/Users/Upload";
import CreateUser from "screens/Users/Create";
import EditUser from "screens/Users/EditUser";

var indexRoutes = [
  {
    path: "all",
    exact: true,
    name: "All Users",
    component: AllUsers,
    layout: "/admin",
  },
  {
    path: "all/:id",
    exact: true,
    invisible:true,
    name: "Groups",
    component: EditUser,
    layout: "/admin",
  },
  {
    path: "upload",
    exact: true,
    name: "Upload Users",
    component: UploadUsers,
    layout: "/admin",
  },
  {
    path: "create",
    exact: true,
    name: "Create User",
    component: CreateUser,
    layout: "/admin",
  },
];

export default indexRoutes;
