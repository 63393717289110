import AllCourses from "./AllCourses";
import CreateOneCourse from "./CreateOneCourse";
import EditCourse from "./EditCourse";
import AllLevels from "./AllLevels";
import AllUnits from "./AllUnits";
import AllLessons from "./AllLessons";
import CreateOneLevel from "./CreateOneLevel";
import CreateOneUnit from "./CreateOneUnit";
import CreateOneLesson from "./CreateOneLesson";
import CreateOneActivity from "./CreateOneActivity";
import EditLevel from "./EditLevel";
import EditUnit from "./EditUnit";
import EditLesson from "./EditLesson";
import AllActivities from "./AllActivities";
import EditActivity from "./EditActivity";
import ActivityBuilder from "./ActivityBuilder";

var indexRoutes = [
  {
    path: "all",
    name: "All Courses",
    component: AllCourses,
  },
  {
    path: "new",
    name: "Create Courses",
    component: CreateOneCourse,
  },
  {
    path: ":id",
    name: "Edit Course",
    component: EditCourse,
  },
  {
    path: ":id/levels",
    name: "All Levels",
    component: AllLevels,
  },
  {
    path: ":id/levels/new",
    name: "Create Level",
    component: CreateOneLevel,
  },
  {
    path: ":courseId/levels/:id",
    name: "Edit Level",
    component: EditLevel,
  },
  {
    path: ":courseId/levels/:levelId/units",
    name: "All Units",
    component: AllUnits,
  },
  {
    path: ":courseId/levels/:levelId/units/new",
    name: "Create Unit",
    component: CreateOneUnit,
  },
  {
    path: ":courseId/levels/:levelId/units/:id",
    name: "Edit Unit",
    component: EditUnit,
  },
  {
    path: ":courseId/levels/:levelId/units/:unitId/lessons",
    name: "All Lessons",
    component: AllLessons,
  },
  {
    path: ":courseId/levels/:levelId/units/:unitId/lessons/new",
    name: "Create Lesson",
    component: CreateOneLesson,
  },
  {
    path: ":courseId/levels/:levelId/units/:unitId/lessons/:id",
    name: "Edit Lesson",
    component: EditLesson,
  },
  {
    path: ":courseId/levels/:levelId/units/:unitId/lessons/:lessonId/:examId/activities",
    name: "All Activities",
    component: AllActivities,
  },
  {
    path: ":courseId/levels/:levelId/units/:unitId/lessons/:lessonId/:examId/activities/new",
    name: "Create Activity",
    component: CreateOneActivity,
  },
  {
    path: ":courseId/levels/:levelId/units/:unitId/lessons/:lessonId/:examId/activities/:id",
    name: "Edit Activity",
    component: EditActivity,
  },
  {
    path: ":courseId/levels/:levelId/units/:unitId/lessons/:lessonId/:examId/activities/:id/builder",
    name: "Build Activity",
    component: ActivityBuilder,
  },
];

export default indexRoutes;
