import gql from "graphql-tag";
import { MEDIA_FIELDS } from "../fragments/media";
export const MEDIA_CREATE = gql`
  mutation insert_media_one($object: media_insert_input!) {
    insert_media_one(object: $object) {
      ...MEDIA_FIELDS
    }
  }
  ${MEDIA_FIELDS}
`;

export const MEDIA_DELETE = gql`
  mutation delete_media_by_pk($id: uuid!) {
    delete_media_by_pk(id: $id) {
      ...MEDIA_FIELDS
    }
  }
  ${MEDIA_FIELDS}
`;
const media_mutations = {
  "media.create": MEDIA_CREATE,
  "media.delete": MEDIA_DELETE,
};
export default media_mutations;
