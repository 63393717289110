import React from "react";
import get from "lodash/get";
import { object, string, number } from "yup";
import { Formik, Form } from "formik";
import { useQuery, useMutation } from "services/Client";
import { useParams, useNavigate } from "react-router-dom";
import { useNotification } from "hooks";

import CreateOrEditLevel from "../shared/CreateOrEditLevel";
export default function All() {
  const { id } = useParams();
  const notification = useNotification();

  let navigate = useNavigate();
  const { data } = useQuery({
    event: "level.get.one",
    variables: {
      id,
    },
    skip: !id,
  });
  const level = get(data, "level_by_pk", {
    name: "",
    order: 0,
    overview: "",
    passingScore: 0
  });

  const { mutate: updateLevel } = useMutation({
    event: "level.update",
    onCompleted: () => {
      notification.success({
        message: "Level Saved",
      });
      navigate(-1);
    },
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: level.name,
        order: level.order,
        overview: level.overview,
        passingScore: level.passingScore
      }}
      onSubmit={(values) =>
        updateLevel({
          variables: {
            _set: {
              ...values,
            },
            pk_columns: { id },
          },
        })
      }
      validationSchema={object({
        name: string().min(5, "error").required("error"),
        order: number().required("error").positive().integer(),
        passingScore: number().required("error").positive().integer(),
        overview: string().required("error"),
      })}
    >
      {({ handleChange, values: { overview } }) => (
        <Form>
          <CreateOrEditLevel
            onChange={handleChange}
            overview={overview}
            onCancel={() => navigate(-1)}
          />
        </Form>
      )}
    </Formik>
  );
}
