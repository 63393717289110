import React from "react";
import { object, string, number } from "yup";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import useCreateLesson from "./useCreateLesson";
import CreateOrEditLesson from "../shared/CreateOrEditLesson";
export default function CreateOneLesson() {
  let navigate = useNavigate();
  const { unitId } = useParams();
  const insertLesson = useCreateLesson(unitId);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: "",
        order: "",
      }}
      onSubmit={(values) =>
        insertLesson({
          variables: {
            object: { ...values, unitId, exam: { data: {} } },
          },
        })
      }
      validationSchema={object({
        name: string().min(5, "error").required("error"),
        order: number().required("error").positive().integer(),
      })}
    >
      <Form>
        <CreateOrEditLesson onCancel={() => navigate(-1)} />
      </Form>
    </Formik>
  );
}
