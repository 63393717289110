import gql from "graphql-tag";
import { ACTIVITY_FIELDS } from "../fragments/activity";

export const ACTIVITY_GET_ONE = gql`
  query activity_by_pk($id: uuid!) {
    activity_by_pk(id: $id) {
      ...ACTIVITY_FIELDS
    }
  }
  ${ACTIVITY_FIELDS}
`;

export const ACTIVITY_GET_MANY = gql`
  query activity($where: activity_bool_exp, $order_by: [activity_order_by!]) {
    activity(where: $where, order_by: $order_by) {
      ...ACTIVITY_FIELDS
    }
  }
  ${ACTIVITY_FIELDS}
`;

const activity_queries = {
  "activity.get.one": ACTIVITY_GET_ONE,
  "activity.get.many": ACTIVITY_GET_MANY,
};

export default activity_queries;
