import gql from "graphql-tag";

import { UNIT_FIELDS } from "../fragments/unit";
export const UNIT_CREATE = gql`
  mutation insert_unit_one($object: unit_insert_input!) {
    insert_unit_one(object: $object) {
      ...UNIT_FIELDS
    }
  }
  ${UNIT_FIELDS}
`;

export const UNIT_UPDATE = gql`
  mutation update_unit_by_pk(
    $pk_columns: unit_pk_columns_input!
    $_set: unit_set_input
  ) {
    update_unit_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...UNIT_FIELDS
    }
  }
  ${UNIT_FIELDS}
`;

export const UNIT_DELETE = gql`
  mutation delete_unit_by_pk($id: uuid!) {
    delete_unit_by_pk(id: $id) {
      ...UNIT_FIELDS
    }
  }
  ${UNIT_FIELDS}
`;

const unit_mutations = {
  "unit.create": UNIT_CREATE,
  "unit.update": UNIT_UPDATE,
  "unit.delete": UNIT_DELETE,
};

export default unit_mutations
