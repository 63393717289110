import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import { useQueryPaginated, useQuery } from "services/Client";
import { useNavigate, useParams } from "react-router-dom";
import Table from "shared/components/Table";
import {
  LinkIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import useCreateBlockListItem from "./useCreateBlockListItem";
import BlocksListForm from "./form";
import useUploadFile from "shared/helpers/useUploadFile";
import useUploadImage from "shared/helpers/useUploadImage";
import useSaveBlock from "./useSaveBlock";
import { AudioPreview, ImagePreview } from "./components";

const goToBlockEdit = (blockItem) => {
  switch (blockItem.type) {
    case "fill_blank":
      return `fillblank/${blockItem.fillBlankId}`;
    case "quiz":
      return `quiz/${blockItem.quizId}`;
    case "text":
      return `text/${blockItem.textId}`;
    case "dnd":
      return `dnd/${blockItem.dndId}`;
    default:
      return;
  }
};

const getBlockId = (blockItem) => {
  switch (blockItem.type) {
    case "fill_blank":
      return blockItem.fillBlankId;
    case "dnd":
      return blockItem.dndId;
    case "quiz":
      return blockItem.quizId;
    case "text":
      return blockItem.textId;
    case "media":
      return blockItem.mediaId;
    default:
      return;
  }
};

const getBlockType = (blockItem) => {
  switch (blockItem.type) {
    case "media":
      return blockItem.media.type;
    default:
      return blockItem.type;
  }
};

const getColumnName = (blockItem) => {
  switch (blockItem) {
    case "fill_blank":
      return "fillBlankId";
    case "quiz":
      return "quizId";
    case "text":
      return "textId";
    case "media":
      return "mediaId";
    case "dnd":
      return "dndId";
    default:
      return "";
  }
};

const typesArray = [
  { id: "quiz", name: "Quiz" },
  { id: "text", name: "Text" },
  { id: "fill_blank", name: "Fill Blanks" },
  { id: "dnd", name: "Drag And Drop" },
  { id: "image", name: "Image" },
  { id: "audio", name: "Audio" },
];

const selectArray = [
  { id: "quiz", name: "Quiz" },
  { id: "text", name: "Text" },
  { id: "fill_blank", name: "Fill Blanks" },
  { id: "dnd", name: "Drag And Drop" },
  { id: "media", name: "Media" },
];

function List() {
  const [tagsFilter, setTagsFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("quiz");
  const [where, setWhere] = useState({
    name: { _ilike: `%${nameFilter}%` },
    type: { _eq: typeFilter },
  });
  const { uploadFile, progress } = useUploadFile();
  const { uploadImage, progress: imageUploadProgress } = useUploadImage();

  const { activityId, size, order } = useParams();
  const submitBlock = useSaveBlock(activityId);

  useEffect(() => {
    if (tagsFilter) {
      setWhere({
        name: { _ilike: `%${nameFilter}%` },
        type: { _eq: typeFilter },
        block_tags: {
          _or: tagsFilter
            .split(",")
            .map((label) => ({ tag: { label: { _ilike: `%${label}%` } } })),
        },
      });
    } else {
      setWhere({
        name: { _ilike: `%${nameFilter}%` },
        type: { _eq: typeFilter },
      });
    }
  }, [tagsFilter, nameFilter, typeFilter]);

  function SelectColumnFilter() {
    return (
      <>
        <select
          id="type"
          name="type"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
          value={typeFilter}
          onChange={(e) => setTypeFilter(e.target.value)}
        >
          {selectArray.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </select>
      </>
    );
  }

  const types = {
    quiz: "Quiz",
    fill_blank: "Fill Blanks",
    dnd: "Drag And Drop",
    text: "Text",
    media: "Media",
    image: "Image",
    audio: "Audio",
    video: "Video",
  };

  let navigate = useNavigate();

  const { data, loading, pageSize } = useQueryPaginated({
    event: "block.list.item.get.many",
    variables: {
      order_by: { createdAt: "desc" },
      where,
    },
  });

  const blocksData = get(data, "block_list_item", []);

  const { data: quizCount } = useQuery({
    event: "block.list.item.count",
    variables: {
      order_by: { createdAt: "desc" },
      where: {
        type: { _eq: typeFilter },
      },
    },
  });

  const count =
    get(quizCount, "block_list_item_aggregate.aggregate.count", 0) / pageSize;

  const {
    data: { searchLike },
  } = useQuery({ event: "searchLike" });

  const { data: tagsData } = useQuery({
    event: "tag.get.many",
    variables: {
      where: {
        label: { _ilike: `%${searchLike}%` },
      },
    },
    skip: searchLike === "",
  });
  const tags = get(tagsData, "tag", []);

  const createBlockListItem = useCreateBlockListItem(types, goToBlockEdit);

  const submit = (values, resetForm) => {
    if (values.type === "audio") {
      const file = values.file;
      uploadFile({
        file,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
      }).then((res) =>
        createBlockListItem({
          variables: {
            object: {
              name: values.name,
              type: "media",
              media: {
                data: {
                  label: values.name,
                  path: res,
                  type: file.type.split("/")[0],
                },
              },
              block_tags: {
                data: values.tagsToSave.map((tag) => ({
                  tagId: tag.id,
                })),
              },
            },
          },
        }).then(() => resetForm())
      );
    } else if (values.type === "image") {
      const file = values.file;
      uploadImage({
        file,
      }).then((res) =>
        createBlockListItem({
          variables: {
            object: {
              name: values.name,
              type: "media",
              media: {
                data: {
                  label: values.name,
                  path: res,
                  type: file.type.split("/")[0],
                },
              },
              block_tags: {
                data: values.tagsToSave.map((tag) => ({
                  tagId: tag.id,
                })),
              },
            },
          },
        }).then(() => resetForm())
      );
    } else {
      createBlockListItem({
        variables: {
          object: {
            name: values.name,
            type: values.type,
            [values.type]: {
              data: {
                title: values.name,
              },
            },
            block_tags: {
              data: values.tagsToSave.map((tag) => ({
                tagId: tag.id,
              })),
            },
          },
        },
      }).then(() => resetForm());
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        canFilter: true,
      },
      {
        Header: "type",
        accessor: "type",
        canFilter: true,
        Filter: SelectColumnFilter,
        Cell: (props) => {
          if (props.row.original.type === "media") {
            return <span>{types[props.row.original.media.type]}</span>;
          }
          return <span>{types[props.row.original.type]}</span>;
        },
      },
      {
        Header: "Tags",
        accessor: "tags",
        canFilter: true,
        Cell: (props) => {
          return (
            <div className="flex space-x-2 -mt-2 flex-wrap">
              {props.row.original.block_tags.map((blockTag) => (
                <span
                  key={blockTag.tag.id}
                  className="inline-flex mt-2 rounded-full items-center py-0.5 px-2.5 text-sm font-medium bg-primary-100 text-primary-700"
                >
                  {blockTag.tag.label}
                </span>
              ))}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        Cell: (props) => {
          return (
            <div className="flex space-x-2">
              {activityId && (
                <button
                  type="button"
                  title="Attach Block"
                  onClick={() =>
                    submitBlock({
                      order,
                      size,
                      type: getBlockType(props.row.original),
                      columnNameType: getColumnName(props.row.original.type),
                      name: props.row.original.name,
                      blockToSaveId: getBlockId(props.row.original),
                    })
                  }
                  className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  <LinkIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              )}
              <button
                type="button"
                title="Edit Block"
                onClick={() =>
                  navigate(`../${goToBlockEdit(props.row.original)}`)
                }
                className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <PencilIcon className="h-4 w-4" aria-hidden="true" />
              </button>
              {props.row.original.type === "media" &&
                props.row.original.media.type === "audio" && (
                  <AudioPreview src={props.row.original.media.path} />
                )}
              {props.row.original.type === "media" &&
                props.row.original.media.type === "image" && (
                  <ImagePreview src={props.row.original.media.path} />
                )}
            </div>
          );
        },
      },
    ],
    [types, order, size, submitBlock, activityId, navigate, SelectColumnFilter]
  );
  return (
    <div className="grid grid-cols-3 gap-6">
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: "",
          tagsToSave: [],
          tag: "",
          type: "quiz",
          file: null,
        }}
        onSubmit={(values, { resetForm }) => submit(values, resetForm)}
        validationSchema={object({
          name: string().required("error"),
        })}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          <Form>
            <BlocksListForm
              tags={tags}
              types={typesArray}
              uploadProgress={progress || imageUploadProgress}
              formik={{
                values: { ...values },
                errors: { ...errors },
                touched: { ...touched },
              }}
              handlers={{
                change: handleChange,
                setFieldValue,
              }}
            />
          </Form>
        )}
      </Formik>

      <div className="-my-2 py-2 col-span-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
          <Table
            onRowClick={(blockItem) =>
              navigate(`../${goToBlockEdit(blockItem)}`)
            }
            columns={columns}
            data={blocksData}
            loading={loading}
            pageCount={count}
            canClickRow={false}
            setters={{
              header_tags: setTagsFilter,
              header_name: setNameFilter,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default List;
