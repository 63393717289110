import gql from "graphql-tag";

import { LESSON_FIELDS } from "../fragments/lesson";

export const LESSON_CREATE = gql`
  mutation insert_lesson_one($object: lesson_insert_input!) {
    insert_lesson_one(object: $object) {
      ...LESSON_FIELDS
    }
  }
  ${LESSON_FIELDS}
`;

export const LESSON_UPDATE = gql`
  mutation update_lesson_by_pk(
    $pk_columns: lesson_pk_columns_input!
    $_set: lesson_set_input
  ) {
    update_lesson_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...LESSON_FIELDS
    }
  }
  ${LESSON_FIELDS}
`;

export const LESSON_DELETE = gql`
  mutation delete_lesson_by_pk($id: uuid!) {
    delete_lesson_by_pk(id: $id) {
      ...LESSON_FIELDS
    }
  }
  ${LESSON_FIELDS}
`;

const lesson_mutations = {
  "lesson.create": LESSON_CREATE,
  "lesson.update": LESSON_UPDATE,
  "lesson.delete": LESSON_DELETE,
};

export default lesson_mutations
