import { useQuery } from "services/Client";
import get from "lodash/get";

export default function useGetData(id) {
  const { data: userData } = useQuery({
    event: "user.get.one",
    variables: {
      id,
    },
    skip: !id,
  });

  const user = get(userData, "user_by_pk", {
    firstName: "",
    lastName: "",
    email: "",
  });

  const { data: progressionData } = useQuery({
    event: "progression.get.many",
    variables: {
      where: {
        userId: { _eq: id },
      },
    },
  });

  const progressions = get(progressionData, "progression", []);

  const {
    data: { searchLike },
  } = useQuery({ event: "searchLike" });

  const { data: coursesData } = useQuery({
    event: "course.get.many",
    variables: {
      where: {
        title: { _ilike: `%${searchLike}%` },
      },
    },
    skip: searchLike === "",
  });
  const courses = get(coursesData, "course", []);

  return { user, progressions, courses };
}
