import gql from "graphql-tag";
import { BLOCK_LIST_FIELDS } from "../fragments/block_list_item";
export const BLOCK_LIST_GET_MANY = gql`
  query block_list_item(
    $limit: Int
    $offset: Int
    $where: block_list_item_bool_exp
    $order_by: [block_list_item_order_by!]
  ) {
    block_list_item(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      ...BLOCK_LIST_FIELDS
    }
  }
  ${BLOCK_LIST_FIELDS}
`;

export const BLOCK_LIST_COUNT = gql`
  query block_list_item_aggregate($where: block_list_item_bool_exp) {
    block_list_item_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const block_queries = {
  "block.list.item.get.many": BLOCK_LIST_GET_MANY,
  "block.list.item.count": BLOCK_LIST_COUNT,
};

export default block_queries;
