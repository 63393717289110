import React from "react";
import { object, string, number } from "yup";
import { Formik, Form } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import CreateOrEditActivity from "../shared/CreateOrEditActivity";
import useCreateActivity from "./useCreateActivity";
export default function CreateOneActivity() {
  let navigate = useNavigate();
  const { lessonId, examId } = useParams();

  const insertActivity = useCreateActivity(lessonId);

  const submit = (values) => {
    const { exam, ...rest } = values;
    if (exam) {
      insertActivity({
        variables: {
          object: { ...rest, lessonId, examId },
        },
      });
    } else {
      insertActivity({
        variables: {
          object: { ...rest, lessonId },
        },
      });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: "",
        order: "",
        exam: false,
      }}
      onSubmit={(values) =>
        submit(values)
      }
      validationSchema={object({
        name: string().min(5, "error").required("error"),
        order: number().required("error").positive().integer(),
      })}
    >
      {({ handleChange, values: { exam } }) => (
        <Form>
          <CreateOrEditActivity
            onChange={handleChange}
            exam={exam}
            onCancel={() => navigate(-1)}
          />{" "}
        </Form>
      )}
    </Formik>
  );
}
