import gql from "graphql-tag";
import { USER_FIELDS } from "../fragments/user";

export const USER_GET_MANY = gql`
  query user(
    $limit: Int
    $offset: Int
    $where: user_bool_exp
    $order_by: [user_order_by!]
  ) {
    user(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`;

export const USER_GET_ONE = gql`
  query user_by_pk($id: uuid!) {
    user_by_pk(id: $id) {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`;

export const USER_GET_COUNT = gql`
  query user_aggregate {
    user_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const USER_REPORT = gql`
  query userReport(
    $where: UserPlacementTestWhereInput
    $report: ReportWhereInput
  ) {
    userReport(where: $where, report: $report)
  }
`;

const user_queries = {
  "user.get.many": USER_GET_MANY,
  "user.get.one": USER_GET_ONE,
  "user.get.count": USER_GET_COUNT,
  "user.report": USER_REPORT,
};

export default user_queries;
