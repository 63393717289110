import gql from "graphql-tag";
import { QUESTION_FIELDS } from "../fragments/question";

export const QUESTION_CREATE = gql`
  mutation insert_question_one($object: question_insert_input!) {
    insert_question_one(object: $object) {
      ...QUESTION_FIELDS
    }
  }
  ${QUESTION_FIELDS}
`;

export const QUESTION_UPDATE = gql`
  mutation update_question_by_pk(
    $pk_columns: question_pk_columns_input!
    $_set: question_set_input
  ) {
    update_question_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...QUESTION_FIELDS
    }
  }
  ${QUESTION_FIELDS}
`;

export const QUESTION_DELETE = gql`
  mutation delete_question_by_pk($id: uuid!) {
    delete_question_by_pk(id: $id) {
      ...QUESTION_FIELDS
    }
  }
  ${QUESTION_FIELDS}
`;

const question_mutations = {
  "question.create": QUESTION_CREATE,
  "question.update": QUESTION_UPDATE,
  "question.delete": QUESTION_DELETE,
};

export default question_mutations;
