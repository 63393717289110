import gql from "graphql-tag";
import { DND_FIELDS } from "../fragments/dnd";

export const DND_UPDATE = gql`
  mutation update_dnd_by_pk(
    $pk_columns: dnd_pk_columns_input!
    $_set: dnd_set_input
  ) {
    update_dnd_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...DND_FIELDS
    }
  }
  ${DND_FIELDS}
`;

const dnd_mutations = {
  "dnd.update": DND_UPDATE,
};

export default dnd_mutations;
