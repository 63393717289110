import React from "react";

export default function RecordButton({ handleRecord, iconPath, canRecord }) {
  return (
    <div className="flex flex-col justify-center">
      <div
        className={`rounded-full bg-white h-16 w-16 flex self-center items-center justify-center ${
          canRecord && "hover:bg-blue-100 cursor-pointer"
        } border ${canRecord ? "border-blue-500" : "border-gray-500"} `}
        onClick={
          canRecord
            ? handleRecord
            : () => {
                return;
              }
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-6 h-6 ${canRecord ? "text-blue-600" : "text-gray-600"}`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d={iconPath} />
        </svg>
      </div>
      <span className="inline-flex items-center mt-2 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800">
        Record
      </span>
    </div>
  );
}
