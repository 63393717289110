import gql from "graphql-tag";
import { LEVEL_FIELDS } from "../fragments/level";
export const LEVEL_GET_ONE = gql`
  query level_by_pk($id: uuid!) {
    level_by_pk(id: $id) {
      ...LEVEL_FIELDS
    }
  }
  ${LEVEL_FIELDS}
`;

export const LEVEL_GET_MANY = gql`
  query level($where: level_bool_exp, $order_by: [level_order_by!]) {
    level(where: $where, order_by: $order_by) {
      ...LEVEL_FIELDS
    }
  }
  ${LEVEL_FIELDS}
`;

const level_queries = {
  "level.get.one": LEVEL_GET_ONE,
  "level.get.many": LEVEL_GET_MANY,
};

export default level_queries;
