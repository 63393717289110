import gql from "graphql-tag";
import { PROGRESSION_FIELDS } from "../fragments/progression";
export const PROGRESSION_GET_ONE = gql`
  query progression_by_pk($id: uuid!) {
    progression_by_pk(id: $id) {
      ...PROGRESSION_FIELDS
    }
  }
  ${PROGRESSION_FIELDS}
`;

export const PROGRESSION_GET_MANY = gql`
  query progression(
    $where: progression_bool_exp
  ) {
    progression(where: $where) {
      ...PROGRESSION_FIELDS
    }
  }
  ${PROGRESSION_FIELDS}
`;

const progression_queries = {
  "progression.get.one": PROGRESSION_GET_ONE,
  "progression.get.many": PROGRESSION_GET_MANY,
};

export default progression_queries;
// level => units
// unit => lessons
// lesson => higlighted lesson
