import gql from "graphql-tag";

export const TAG_GET_MANY = gql`
  query tag(
    $limit: Int
    $offset: Int
    $where: tag_bool_exp
    $order_by: [tag_order_by!]
  ) {
    tag(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      id
      label
    }
  }
`;


const text_queries = {
  "tag.get.many": TAG_GET_MANY,
};

export default text_queries;
