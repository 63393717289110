import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export default function Pagination({ pageCount: controlledPageCount }) {
  const [isPrevDisabled, setIsPrev] = useState(false);
  const [isNextDisabled, setIsNext] = useState(false);

  let navigate = useNavigate();
  function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryParams();
  const pageIndex = parseInt(query.get("page"));
  const pageSize = parseInt(query.get("pageSize"));
  const order = parseInt(query.get("order"));
  const size = query.get("size");

  const activityId = query.get("attach");
  const prevPage = activityId
    ? `?attach=${activityId}&order=${order}&size=${size}&page=${
        pageIndex - 1
      }&pageSize=${pageSize}`
    : `?page=${pageIndex - 1}&pageSize=${pageSize}`;
  const nextPage = activityId
    ? `?attach=${activityId}&order=${order}&size=${size}&page=${
        pageIndex + 1
      }&pageSize=${pageSize}`
    : `?page=${pageIndex + 1}&pageSize=${pageSize}`;

  useEffect(() => {
    if (pageIndex === 0) {
      setIsPrev(true);
    }
    if (pageIndex > 0 && isPrevDisabled === true) {
      setIsPrev(false);
    }
  }, [pageIndex, isPrevDisabled]);

  useEffect(() => {
    const disabledCondition =
      pageIndex + 1 >= Math.ceil(controlledPageCount / pageSize);
    if (disabledCondition) {
      setIsNext(true);
    }
    if ((isNextDisabled === true) & !disabledCondition) {
      setIsNext(false);
    }
  }, [pageIndex, isNextDisabled, pageSize, controlledPageCount]);
  return (
    <div className="bg-white px-4 py-3 mt-5 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:block">
        <p className="text-sm leading-5 text-gray-700">
          Showing
          <span className="font-medium"> {pageIndex * pageSize + 1} </span>
          to
          <span className="font-medium">
            {" "}
            {pageSize * pageIndex + pageSize > controlledPageCount
              ? controlledPageCount
              : pageSize * pageIndex + pageSize}{" "}
          </span>
          of
          <span className="font-medium"> {controlledPageCount} </span>
          results
        </p>
      </div>

      <div className="flex justify-between sm:justify-end">
        <button
          disabled={isPrevDisabled}
          onClick={() =>
            navigate({
              search: prevPage,
            })
          }
          className={`${
            isPrevDisabled && "cursor-not-allowed"
          } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
        >
          Previous
        </button>
        <button
          disabled={isNextDisabled}
          onClick={() =>
            navigate({
              search: nextPage,
            })
          }
          className={`${
            isNextDisabled && "cursor-not-allowed"
          } ml-3 relative inline-flex disabled:cursor-not-allowed items-center px-4 py-2 disabled:cursor-pointer border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
        >
          Next
        </button>
      </div>
    </div>
  );
}
