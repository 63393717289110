import React from "react";
import { useQuery, useMutation } from "services/Client";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import View from "./view";
import useCreateQuestion from "./hooks/useCreateQuestion";
import useCreateQuestionOption from "./hooks/useCreateQuestionOption";
import useDeleteQuestion from "./hooks/useDeleteQuestion";
import useDeleteQuestionOption from "./hooks/useDeleteQuestionOption";

function Quiz() {
  
  const { id } = useParams();

  const { data: quizData } = useQuery({
    event: "fill.blank.get.one",
    variables: { id },
  });

  const title = get(quizData, "fill_blank_by_pk.title");

  const { data, refetch } = useQuery({
    event: "question.get.many",
    variables: {
      where: { fillBlankId: { _eq: id } },
      order_by: { order: "asc" },
    },
  });

  const questions = get(data, "question", []);

  const { data: questionOptionData } = useQuery({
    event: "questionOption.get.many",
    variables: {
      where: { fillBlankId: { _eq: id } },
      order_by: { order: "asc" },
    },
    skip: !id,
  });
  const options = get(questionOptionData, "question_option", []);

  const { mutate: updateFillBlank } = useMutation({
    event: "fill.blank.update",
  });
  const createQuestion = useCreateQuestion(id);
  const createQuestionOption = useCreateQuestionOption();
  const deleteQuestion = useDeleteQuestion(id);
  const deleteQuestionOption = useDeleteQuestionOption(refetch);
  // const createQuestionOption = useCreateQuestionOption(questionId, data);

  const { mutate: updateQuestion } = useMutation({ event: "question.update" });
  const { mutate: updateQuestionOption } = useMutation({
    event: "questionOption.update",
  });

  const handleUpdateFillBlank = (mutate) => (title) => {
    mutate({
      variables: { pk_columns: { id }, _set: { title } },
    });
  };

  const handleUpdateQuestion = (mutate) => (id, data) => {
    if (id) {
      mutate({
        variables: { pk_columns: { id }, _set: { ...data } },
      });
    }
  };

  const handleUpdateQuestionOption = (mutate) => (id, data) => {
    if (id) {
      mutate({
        variables: { pk_columns: { id }, _set: { ...data } },
      });
    }
  };

  const handleCreateQuestion = (mutate) => (label, cb) => {
    mutate({
      variables: {
        object: {
          label,
          order: (questions.length + 1).toString(),
          fillBlankId: id,
        },
      },
    }).then(() => cb());;
  };

  const handleCreateQuestionOption = (mutate) => (label, cb) => {
    mutate({
      variables: {
        object: {
          label,
          order: (options.length + 1).toString(),
          fillBlankId: id,
        },
      },
    }).then(() => cb());
  };

  const handleDeleteQuestion = (mutate) => (id) => {
    if (id) {
      mutate({
        variables: { id },
      });
    }
  };

  const handleDeleteQuestionOption = (mutate) => (id) => {
    if (id) {
      mutate({
        variables: { id },
      });
    }
  };

  return (
    <>
      <View
        data={{ questions, title, options }}
        mutations={{
          handleUpdateQuestion: handleUpdateQuestion(updateQuestion),
          handleUpdateQuestionOption:
            handleUpdateQuestionOption(updateQuestionOption),
          handleCreateQuestion: handleCreateQuestion(createQuestion),
          handleCreateQuestionOption:
            handleCreateQuestionOption(createQuestionOption),
          handleDeleteQuestion: handleDeleteQuestion(deleteQuestion),
          handleDeleteQuestionOption:
            handleDeleteQuestionOption(deleteQuestionOption),
          handleUpdateFillBlank: handleUpdateFillBlank(updateFillBlank),
        }}
      />
    </>
  );
}
export default Quiz;
