import { gql } from "@apollo/client";

export const ACTIVITY_FIELDS = gql`
  fragment ACTIVITY_FIELDS on activity {
    id
    name
    order
    lessonId
    examId
  }
`;
