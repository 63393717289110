import React from "react";
export default function AudioPreview({ src, onClick, loading }) {
  return (
    <div className="flex flex-col justify-center">
      <div
        className={`rounded-full bg-white h-10 w-10 flex self-center items-center justify-center ${
          src && "hover:bg-blue-100 cursor-pointer"
        } ${!loading && "border"} ${
          src ? "border-blue-500" : "border-gray-500"
        } `}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={`w-4 h-4 ${src ? "text-blue-600" : "text-gray-600"}`}
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" />
        </svg>
      </div>
      <span className="inline-flex items-center mt-2 px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-gray-100 text-gray-800">
        Save
      </span>
    </div>
  );
}
