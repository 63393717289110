import React from "react";
import get from "lodash/get";
import View from "./view";

export default function FillBlank({ fill_blank }) {
  const questions = get(fill_blank, "questions", []);

  const options = get(fill_blank, "question_options", []);

  return (
    <>
      <View data={{ questions, options }} />
    </>
  );
}
