import gql from "graphql-tag";
import { BLOCK_LIST_FIELDS } from "../fragments/block_list_item";
export const BLOCK_LIST_ITEM_CREATE = gql`
  mutation insert_block_list_item_one($object: block_list_item_insert_input!) {
    insert_block_list_item_one(object: $object) {
      ...BLOCK_LIST_FIELDS
    }
  }
  ${BLOCK_LIST_FIELDS}
`;

const block_list_item_mutations = {
  "block.list.item.create": BLOCK_LIST_ITEM_CREATE,
};

export default block_list_item_mutations
