import React from "react";
import CardN from "shared/components/CardN";
import Pagination from "shared/components/Pagination";

import AddButton from "shared/components/AddButton";
import { useNavigate } from "react-router-dom";
import CardNSkeleton from "shared/components/CardNSkeleton";

export default function ListView({
  data,
  count,
  loading,
  handleCreate,
  handleDelete,
}) {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="grid grid-cols-4 gap-5">
          {!loading ? (
            <>
              {data.map((test) => (
                <CardN
                  key={test.id}
                  title={test.title}
                  onEdit={() =>
                    navigate(`../${test.id}`)
                  }
                  onDelete={() => handleDelete(test.id)}
                  onAdd={() =>
                    navigate(`../${test.id}/levels`)
                  }
                />
              ))}
              <AddButton handleClick={handleCreate} />
            </>
          ) : (
            <>
              {[1, 2, 3, 4, 5, 6, 7].map((key) => (
                <CardNSkeleton key={key} />
              ))}
            </>
          )}
        </div>
        <Pagination pageCount={count} />
        {/* <Paginator
          count={Math.ceil(count)}
          current={parseInt(page)}
          handler={(page) => history.push(`?page=${page}&pageSize=${pageSize}`)}
        /> */}
      </div>
    </div>
  );
}
