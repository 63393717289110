import { gql } from "@apollo/client";

export const SECTION_FIELDS = gql`
  fragment SECTION_FIELDS on section {
    id
    name
    order
    timeout
  }
`;
