import { gql } from "@apollo/client";

export const QUESTION_OPTION_FIELDS = gql`
  fragment QUESTION_OPTION_FIELDS on question_option {
    id
    label
    order
    hasMath
    rightAnswer
    questionId
    fillBlankId
    dndId
  }
`;
