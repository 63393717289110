import gql from "graphql-tag";
import { MEDIA_FIELDS } from "../fragments/media";
export const MEDIA_GET_MANY = gql`
  query media(
    $limit: Int
    $offset: Int
    $where: media_bool_exp
    $order_by: [media_order_by!]
  ) {
    media(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...MEDIA_FIELDS
    }
  }
  ${MEDIA_FIELDS}
`;

export const MEDIA_COUNT = gql`
  query media_aggregate {
    media_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const audio_queries = {
  "media.get.many": MEDIA_GET_MANY,
  "media.count": MEDIA_COUNT,
};

export default audio_queries;
