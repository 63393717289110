import React from "react";
import Autosuggest from "shared/components/Autosuggest";
import { FieldArray, Field } from "formik";
import CustomInput from "shared/components/CustomInput";

const Chip = ({ label, onClose, onEdit }) => (
  <span className="inline-flex mt-1 rounded-md items-center py-2 px-3 text-sm font-medium bg-primary-100 text-primary-700">
    {label}

    {onClose && (
      <button
        type="button"
        className="flex-shrink-0 ml-2 h-6 w-6 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white"
      >
        <span className="sr-only">Remove large option</span>
        <svg
          className="h-3 w-3"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    )}

    {onEdit && (
      <button
        type="button"
        onClick={onEdit}
        className="flex-shrink-0 ml-1 h-6 w-6 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white"
      >
        <span className="sr-only">Remove large option</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      </button>
    )}
  </span>
);

export default function View({ handlers, formik, courses, progressions, setProgressionId }) {
  const { change } = handlers;
  //console.log(groupIds);
  const {
    values: { course, coursesToSave },
  } = formik;
  return (
    <>
      <div>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Personal Information
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            You can edit user information, add groups, reset tests.
          </p>
        </div>
        <div className="mt-6 sm:mt-5">
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="first_name"
              className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              First name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
                <Field type="text" name="first_name" component={CustomInput} />
              </div>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="last_name"
              className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Last name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
                <Field type="text" name="last_name" component={CustomInput} />
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Email address
            </label>
            <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
              <Field type="email" name="email" component={CustomInput} />
            </div>
          </div>
          <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Add to course
            </label>
            <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
              <FieldArray
                name="coursesToSave"
                render={(arrayHelpers) => (
                  <div>
                    <div className="max-w-lg rounded-md sm:max-w-xs">
                      <Autosuggest
                        data={courses}
                        getOptionLabel={(option) => option.title}
                        onSuggestionSelected={(e, { suggestion }) => {
                          arrayHelpers.push({
                            id: suggestion.id,
                            title: suggestion.title,
                            levelId: suggestion.levels[0].id,
                            unitId: suggestion.levels[0].units[0].id,
                          });
                          change("course")("");
                        }}
                        {...{
                          placeholder: "Search course...",
                          value: course,
                          onChange: (event, { newValue }) =>
                            change("course")(newValue),
                        }}
                      />
                    </div>{" "}
                    <div className="sm:rounded-md divide-y flex flex-wrap space-x-2 divide-gray-200 mt-3">
                      {coursesToSave.map((tag, index) => (
                        <Chip key={tag.id} label={tag.title} />
                      ))}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Courses
            </label>
            <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
              {progressions.map((progression) => (
                <Chip key={progression.id} label={progression.course.title} onEdit={()=>setProgressionId(progression.id)} />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-gray-200 pt-5">
        <div className="flex justify-end">
          <span className="ml-3 inline-flex rounded-md shadow-sm">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
}
