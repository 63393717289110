import course from "./course";
import level from "./level";
import unit from "./unit";
import lesson from "./lesson";

import client from "./client";
import quiz from "./quiz";
import media from "./media";
import section from "./section";
import userActivity from "./userActivity";
import activity from "./activity";
import activity_layout_queries from "./activity_layout";
import user from "./user";
import text from "./text";
import block from "./block";
import question from "./question";
import questionOption from "./questionOption";
import progression from "./progression";
import fill_blank from "./fill_blank"
import block_list_item from "./block_list_item"
import tag from "./tag"
import block_tag from "./block_tag"

import dnd from "./dnd"

const queries = {
  ...course,
  ...level,
  ...unit,
  ...lesson,
  ...client,
  ...fill_blank,
  ...dnd,
  ...block_list_item,
  ...section,
  ...quiz,
  ...tag,
  ...block_tag,
  ...userActivity,
  ...activity,
  ...activity_layout_queries,
  ...block,
  ...question,
  ...questionOption,
  ...media,
  ...text,
  ...user,
  ...progression,
};
export default queries;
