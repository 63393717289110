import gql from "graphql-tag";
import { ACTIVITY_LAYOUT_FIELDS } from "../fragments/activity_layout";
export const ACTIVITY_LAYOUT_CREATE = gql`
  mutation insert_activity_layout_one($object: activity_layout_insert_input!) {
    insert_activity_layout_one(object: $object) {
      ...ACTIVITY_LAYOUT_FIELDS
    }
  }
  ${ACTIVITY_LAYOUT_FIELDS}
`;

export const ACTIVITY_LAYOUT_UPDATE = gql`
  mutation update_activity_layout_by_pk(
    $pk_columns: activity_layout_pk_columns_input!
    $_set: activity_layout_set_input
  ) {
    update_activity_layout_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...ACTIVITY_LAYOUT_FIELDS
    }
  }
  ${ACTIVITY_LAYOUT_FIELDS}
`;

export const ACTIVITY_LAYOUT_DELETE = gql`
  mutation delete_activity_layout_by_pk($id: uuid!) {
    delete_activity_layout_by_pk(id: $id) {
      ...ACTIVITY_LAYOUT_FIELDS
    }
  }
  ${ACTIVITY_LAYOUT_FIELDS}
`;

const activity_layout_mutations = {
  "activity.layout.create": ACTIVITY_LAYOUT_CREATE,
  "activity.layout.update": ACTIVITY_LAYOUT_UPDATE,
  "activity.layout.delete": ACTIVITY_LAYOUT_DELETE,
};

export default activity_layout_mutations;
