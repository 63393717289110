import React from "react";
import { object, string, mixed } from "yup";
import { Formik, Form } from "formik";
import CreateOrEditCourse from "../shared/CreateOrEditCourse";
import useCreatePlacementTest from "./useCreatePlacementTest";
import useUploadFile from "shared/helpers/useUploadFile";
export default function EditCourse() {
  const insertTest = useCreatePlacementTest();
  const { uploadFile } = useUploadFile();
  return (
    <Formik
      initialValues={{
        title: "",
        file: null,
      }}
      onSubmit={async (values) => {
        const { file, title } = values;
        const image = await uploadFile({
          file,
          fileName: file.name,
          fileSize: file.size,
          fileType: file.type,
        });
        insertTest({
          variables: {
            object: { title, image },
          },
        });
      }}
      validationSchema={object({
        title: string().min(5, "error").required("error"),
        file: mixed().required("no image"),
      })}
    >
      {({ setFieldValue, values: { file } }) => (
        <Form>
          <CreateOrEditCourse
            onChange={setFieldValue}
            file={file}
            mode="create"
          />
        </Form>
      )}
    </Formik>
  );
}
