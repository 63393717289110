import React from "react";
// import ProgressiveImage from "react-progressive-image-loading";

// core components
function ImageBlock({ src }) {
  console.log("toto")
  return (
    <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-primary-500 overflow-hidden">
      <img
        src={src}
        alt=""
        className="object-cover pointer-events-none group-hover:opacity-75"
      />
    </div>
  );
}

export default ImageBlock;
