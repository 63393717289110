import gql from "graphql-tag";
import { FILL_BLANK_FIELDS } from "../fragments/fill_blank";

export const FILL_BLANK_UPDATE = gql`
  mutation update_fill_blank_by_pk(
    $pk_columns: fill_blank_pk_columns_input!
    $_set: fill_blank_set_input
  ) {
    update_fill_blank_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...FILL_BLANK_FIELDS
    }
  }
  ${FILL_BLANK_FIELDS}
`;

const fill_blank_mutations = {
  "fill.blank.update": FILL_BLANK_UPDATE,
};

export default fill_blank_mutations;
