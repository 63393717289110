import React, { useRef } from "react";
import Item from "./components/item";
import Button from "components/ButtonWithLoader";
import { CloudUploadIcon } from "@heroicons/react/solid";
export default function View({
  handleFile,
  files,
  uploadedFiles,
  handleRemoveFile,
  handleUpload,
  loading,
}) {
  // console.log(bar);
  const fileInput = useRef(null);
  return (
    <div className="max-w-lg flex  justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
      <div className="flex flex-col items-center space-y-4 w-full">
        <span
          className={
            "bg-primary-500 h-20 w-20 flex rounded-lg items-center justify-center ring-8 ring-white"
          }
        >
          <CloudUploadIcon className="w-16 h-16 text-white" aria-hidden="true" />
        </span>
        <p className="mt-1 text-sm text-gray-600">
          <button
            type="button"
            onClick={() => fileInput.current.click()}
            className="font-medium text-primary-600 hover:text-primary-500 focus:outline-none focus:underline transition duration-150 ease-in-out"
          >
            Choose files
          </button>
          <input
            type="file"
            multiple
            onChange={(e) => handleFile(e.target.files)}
            hidden
            ref={fileInput}
          />
        </p>
        <p className="mt-1 text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
        {uploadedFiles.length > 0 && (
          <div className="mt-3">
            <span>Saved files</span>
            {uploadedFiles.map((file) => (
              <Item
                key={file}
                saved={true}
                name={file.split("/").slice(-1)[0]}
              />
            ))}
          </div>
        )}
        <div className="mt-3">
          <span className="text-sm text-gray-900">File queue: {files.length}</span>
          {files.map((file, i) => (
            <Item
              key={i}
              name={file.name}
              handleRemove={() => handleRemoveFile(i)}
            />
          ))}
        </div>
        {files.length > 0 && (
          // <span className="inline-flex rounded-md shadow-sm mt-2">
          //   <button
          //     type="button"
          //     onClick={handleUpload}
          //     className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition ease-in-out duration-150"
          //   >
          //     Save
          //     <svg
          //       className="ml-3 -mr-1 h-5 w-5"
          //       fill="currentColor"
          //       viewBox="0 0 20 20"
          //     >
          //       <path
          //         fillRule="evenodd"
          //         d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884zM18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"
          //         clipRule="evenodd"
          //       />
          //     </svg>
          //   </button>
          // </span>
          <span className="inline-flex rounded-md shadow-sm mt-2">
            <Button loading={loading} handleClick={handleUpload} />
          </span>
        )}
      </div>
    </div>
  );
}
