import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { useNavigate } from "react-router-dom";
import get from "lodash/get";
import { ACTIVITY_LAYOUT_GET_MANY } from "services/Client/schemas/queries/activity_layout";
export default function useSaveBlock(activityId) {
  const notification = useNotification();
  let navigate = useNavigate();
  const { mutate: createActivityLayout } = useMutation({
    event: "activity.layout.create",
    update: ({ data: { insert_activity_layout_one } }, cache) => {
      const prevData = cache.readQuery({
        query: ACTIVITY_LAYOUT_GET_MANY,
        variables: {
          where: { activityId: { _eq: activityId } },
          order_by: { order: "asc" },
        },
      });
      const data = {
        activity_layout: [
          ...prevData.activity_layout,
          insert_activity_layout_one,
        ],
      };
      cache.writeQuery({
        query: ACTIVITY_LAYOUT_GET_MANY,
        data,
        variables: {
          where: { activityId: { _eq: activityId } },
          order_by: { order: "asc" },
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Block successfully attached to activity",
        buttonLabel: `Go back`,
          buttonAction: () =>
            navigate(-1),
      });
      navigate(-1);
    },
  });
  const { mutate: createBlock } = useMutation({
    event: "block.create",
  });
  const submitBlock = async ({ order, size, type, columnNameType, name, blockToSaveId }) => {
    const result = await createBlock({
      variables: {
        object: {
          name,
          type,
          activityId,
          [columnNameType]: blockToSaveId,
        },
      },
    });
    const blockId = get(result, "data.insert_block_one.id");
    if (blockId) {
      createActivityLayout({
        variables: {
          object: {
            order,
            blockId,
            activityId,
            size,
          },
        },
      });
    } else {
      notification.error({ message: "Error" });
    }
  };
  return submitBlock;
}
