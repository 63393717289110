import gql from "graphql-tag";
import { TEXT_FIELDS } from "../fragments/text";

export const TEXT_GET_ONE = gql`
  query text_by_pk($id: uuid!) {
    text_by_pk(id: $id) {
      ...TEXT_FIELDS
    }
  }
  ${TEXT_FIELDS}
`;

export const TEXT_GET_MANY = gql`
  query text(
    $limit: Int
    $offset: Int
    $where: text_bool_exp
    $order_by: [text_order_by!]
  ) {
    text(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...TEXT_FIELDS
    }
  }
  ${TEXT_FIELDS}
`;

export const TEXT_COUNT = gql`
  query text_aggregate {
    text_aggregate {
      aggregate {
        count
      }
    }
  }
`;

const text_queries = {
  "text.get.one": TEXT_GET_ONE,
  "text.get.many": TEXT_GET_MANY,
  "text.count": TEXT_COUNT
};

export default text_queries;
