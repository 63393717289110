import React from "react";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import Option from "./components/option";
import NavButton from "./components/nav";
import TeX from "@matejmazur/react-katex";
import Image from "../Image";
import "katex/dist/katex.min.css";

export default function View(props) {
  const { data, onAnswer, handler } = props;

  const { answers, question, length, index, missedQuestions } = data;

  const questionImage = get(question, "image.cloudinaryId");
  return (
    <div className="m-auto  p-3  bg-gray-50 rounded-lg overflow-hidden  shadow-lg">
      <div className=" flex flex-row justify-between m-4 items-center ">
        <p className="font-black">Quiz</p>
      </div>
      <div className="bg-white m-auto rounded overflow-hidden">
        <div className="flex flex-col my-6 mx-3 max-w-full">
          <div className="flex justify-end items-center">
            {/* <div className="flex items-center justify-center">
              <p className="font-medium mb-1 text-gray-600">
                You have answered {Object.keys(answers).length} / {length}{" "}
                questions
              </p>

              <button
                type="button"
                disabled={missedQuestions.length <= 0}
                onClick={() => {
                  handler({ type: "go-to-missed" });
                }}
                className="inline-flex items-center ml-3 mr-4 px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-50 focus:outline-none focus:border-primary-300 focus:shadow-outline-blue active:bg-blue-200 transition ease-in-out duration-150"
              >
                go to unanswered questions
                {missedQuestions.length > 0
                  ? ` (${missedQuestions.length})`
                  : ""}
              </button>
            </div> */}

            <div className="flex flex-col items-center justify-center">
              <NavButton
                first={index + 1 === 1}
                last={index + 1 === length}
                //  length={length}
                setIndex={handler}
              />
            </div>
          </div>

          <div className="overflow-x-auto">
            {question.hasMath ? (
              <TeX>{`${index + 1} - ${question.label}`}</TeX>
            ) : (
              <p className="font-medium text-xl" id="question">
                {index + 1} - {question.label}
              </p>
            )}
          </div>
          {questionImage && (
            <div key={questionImage} className="grid grid-cols-4">
              <div className="col-start-2 col-span-2">
                <Image src={questionImage} />
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="mt-6">
          {sortBy(get(question, "question_options", []), ["order"]).map(
            (option, index) => {
              return (
                <Option
                  option={option}
                  questionId={question.id}
                  key={option.id}
                  index={index}
                  focus={false}
                  setObj={onAnswer}
                  obj={answers}
                  math={option.hasMath}
                />
              );
            }
          )}
        </div>
      </div>
      {/* <div className=" flex justify-center mt-4  ">
        <button
          type="button"
          className=" uppercase mb-4 items-center px-4 py-2 border border-black hover:border-blue-500 text-sm leading-5 font-medium  rounded-lg  text-black hover:text-white  bg-transparent hover:bg-blue-500  transition ease-in-out duration-150"
          onClick={onfinish}
        >
          Check answers
        </button>
      </div> */}
    </div>
  );
}
