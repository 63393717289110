import React, { useRef } from "react";
import { NavLink, Route, Routes, Navigate } from "react-router-dom";
import List from "./List";
import routes from "./routes";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const inputRef = useRef(null);

  return (
    <div className=" ">
      {/* <nav className="flex-col m-4 flex w-1/12    ">
        {routes.map((route) => {
          return (
            !route.invisible && (
              <NavLink
                key={route.name}
                to={route.path}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "rounded-md border bg-indigo-500 text-white shadow-blue-2xl"
                      : "",
                    "my-5 w-20 h-20 text-center text-xl font-black flex justify-center items-center"
                  )
                }
              >
                {route.name}
              </NavLink>
            )
          );
        })}
      </nav> */}

      <div className="" ref={inputRef}>
        <div className="hidden sm:block py-5">
          <div className="flex flex-row justify-between py-2">
            <h2 className="text-3xl font-black">Blocks</h2>
          </div>

          <div className="border-t border-gray-200"></div>
        </div>
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={<route.component />}
            />
          ))}
          <Route
            index
            element={
              <>
                <List />
              </>
            }
          />
        </Routes>
      </div>
    </div>
  );
}
