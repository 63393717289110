import React, { useState } from "react";

// import TinnyCharts from "./sections/tinyCharts";
// import BigChart from "./sections/BigChart";
export default function Dashboard() {
  const [type, setType] = useState("");
  console.log("type", type);
  return (
    <div>
      
    </div>
  );
}
