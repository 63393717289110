import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import EditableField from "./components/EditableField";
// import Image from "blocks/Image";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function View(props) {
  const [question, setQuestion] = useState("");
  const [choice, setChoice] = useState("");
  const { data, mutations } = props;

  const { questions, title, options } = data;
  const {
    handleUpdateQuestion,
    handleCreateQuestion,
    handleCreateQuestionOption,
    handleDeleteQuestionOption,
    handleUpdateFillBlank,
    handleDeleteQuestion,
  } = mutations;
  function handleOnDragEnd(result) {
    handleUpdateQuestion(result.destination.droppableId, {
      correctAnswerId: result.draggableId,
    });
  }
  return (
    <div className="m-auto  p-3  bg-gray-50 rounded-lg overflow-hidden  shadow-lg">
      <div className="flex flex-row justify-between my-3 items-center">
        <EditableField
          initialValue={title}
          onSave={handleUpdateFillBlank}
          className="font-medium text-xl"
        />

        <div className="flex space-x-2">
          <input
            type="text"
            name="choice"
            id="choice"
            onChange={(e) => setChoice(e.target.value)}
            value={choice}
            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="insert choice"
          />
          <button
            type="button"
            onClick={() =>
              handleCreateQuestionOption(choice, () => setChoice(""))
            }
            className="inline-flex mr-1 items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition ease-in-out duration-150"
          >
            Save
          </button>
          {/* <Publish
            active={showImageUpload}
            handleClick={() => setShowImageUpload(!showImageUpload)}
          /> */}
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="bg-white m-auto rounded overflow-hidden p-5 flex-col space-y-2">
          <Droppable droppableId="choices">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex space-x-2"
              >
                {options.map((option, index) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id}
                    index={index}
                  >
                    {(provided) => (
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="inline-flex rounded-full items-center py-1.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                      >
                        {option.label}
                        <button
                          type="button"
                          onClick={()=>handleDeleteQuestionOption(option.id)}
                          className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                        >
                          <span className="sr-only">Remove</span>
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div>
            <ul className="divide-y divide-gray-200">
              {questions.map((question) => (
                <li key={question.id} className="py-4">
                  <div className="flex space-x-3">
                    <div className="flex-1 space-y-1">
                      <div className="flex items-center justify-between">
                        <EditableField
                          initialValue={question.label}
                          onSave={(value) =>
                            handleUpdateQuestion(question.id, {
                              label: value,
                            })
                          }
                          className="text-md font-medium"
                          title="double-click to edit"
                        />
                        <div className="flex space-x-2">
                          <Droppable droppableId={question.id}>
                            {(provided) => (
                              <span
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={classNames(
                                  !question.correct_answer && "w-16",
                                  "inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                                )}
                              >
                                {question.correct_answer?.label}
                                {question.correct_answer && (
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleUpdateQuestion(question.id, {
                                        correctAnswerId: null,
                                      })
                                    }
                                    className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                                  >
                                    <span className="sr-only">Remove</span>
                                    <svg
                                      className="h-2 w-2"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 8 8"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeWidth="1.5"
                                        d="M1 1l6 6m0-6L1 7"
                                      />
                                    </svg>
                                  </button>
                                )}
                                {provided.placeholder}
                              </span>
                            )}
                          </Droppable>

                          <button
                            type="button"
                            onClick={() => handleDeleteQuestion(question.id)}
                            className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-primary active:bg-red-700 transition ease-in-out duration-150"
                          >
                            Delete Sentence
                          </button>
                        </div>
                      </div>
                      {/* <p className="text-sm text-gray-500">
                Deployed {activityItem.project} ({activityItem.commit}{" "}
                in master) to {activityItem.environment}
              </p> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex space-x-2">
            <input
              type="text"
              name="question"
              id="question"
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
              className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="insert question"
            />
            <button
              type="button"
              onClick={() =>
                handleCreateQuestion(question, () => setQuestion(""))
              }
              className="inline-flex mr-1 items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition ease-in-out duration-150"
            >
              Save
            </button>
          </div>
        </div>
      </DragDropContext>
      {/* <div className=" flex justify-center mt-4  ">
        <button
          type="button"
          className=" uppercase mb-4 items-center px-4 py-2 border border-black hover:border-primary-500 text-sm leading-5 font-medium  rounded-lg  text-black hover:text-white  bg-transparent hover:bg-primary-500  transition ease-in-out duration-150"
          onClick={onfinish}
        >
          Check answers
        </button>
      </div> */}
    </div>
  );
}
