import React from "react";
import { cache as ClientCache } from "services/Client";
const { alertVar } = ClientCache;
const AlertManager = (App) => {
  const withAlert = ({ ...rest }) => {
    const error = (message) => {
      alertVar({
        message,
        type: "error",
        opened: true,
        __typename: "alert",
      });
    };

    const success = (message) => {
      alertVar({
        message,
        type: "success",
        opened: true,
        __typename: "alert",
      });
    };

    const warning = (message) => {
      alertVar({
        message,
        type: "warning",
        opened: true,
        __typename: "alert",
      });
    };

    const info = (message) => {
      alertVar({
        message,
        type: "info",
        opened: true,
        __typename: "alert",
      });
    };
    const close = () => {
      alertVar({
        message: "",
        type: "info",
        opened: false,
        __typename: "alert",
      });
    };
    const alert = {
      error,
      success,
      warning,
      info,
      close,
    };
    return <App {...rest} alert={alert} />;
  };
  

  return withAlert;
};

export default AlertManager;
