import React from "react";
import get from "lodash/get";
import { useQueryPaginated, useQuery, useMutation } from "services/Client";
import { useNavigate } from "react-router-dom";
import withNotification from "services/Notification";
import List from "./View";
function All(props) {
  const { data, loading } = useQueryPaginated({
    event: "course.get.many",
    variables: {
      order_by: { createdAt: "desc" },
    },
  });

  const { data: dataCount } = useQuery({
    event: "course.count",
  });

  const courseData = get(data, "course", []);
  const count = get(dataCount, "course_aggregate.aggregate.count", []);

  const { mutate: deletecourse } = useMutation({
    event: "course.delete",
  });
  let navigate = useNavigate();
  
  const removecourse = async (mutate, id) => {
    mutate({ variables: { where: { id } } })
      .then((res) => {
        if (get(res, "data.deletePlacementcourse.id")) {
          props.notification.success("Delete Successful");
        } else {
          props.notification.error("Error");
        }
      })
      .catch((e) => {});
  };
  return (
    <List
      data={courseData}
      count={count}
      loading={loading}
      handleCreate={() => navigate("../new")}
      handleDelete={(id) => removecourse(deletecourse, id)}
    />
  );
}

export default withNotification(All);
