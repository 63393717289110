import React, { useState } from "react";
import { useQuery } from "services/Client";
import get from "lodash/get";
import Quiz from "./blocks/Quiz";
import Image from "./blocks/Image";
import Text from "./blocks/Text";
import Audio from "./blocks/Audio";
import TextEditor from "./blocks/TextEditor";
import Speaking from "./blocks/Speaking";
import Upload from "./blocks/Upload";
import FillBlank from "./blocks/FillBlank";
import Dnd from "./blocks/Dnd";

const BlockView = ({ isFull, blockId, isStatic, type, handlers }) => {
  const [show, setShow] = useState(false);
  const { data } = useQuery({
    event: "block.get.one",
    variables: {
      id: blockId,
    },
    skip: !blockId,
  });
  const { updateWidth, deleteBlock } = handlers;
  let block = get(data, "block_by_pk");
  let quiz = get(block, "quiz", {});
  let fill_blank = get(block, "fill_blank", {});
  let dnd = get(block, "dnd", {});
  let image = get(block, "media.path", "");
  let text = get(block, "text.content", "");
  let audio = get(block, "media.path", "");
  console.log(block)
  const dynamicMapper = {
    image: <Image src={image} />,
    quiz: <Quiz quiz={quiz} />,
    fill_blank: <FillBlank fill_blank={fill_blank} />,
    dnd: <Dnd dnd={dnd} />,
    text: <Text content={text} />,
    audio: <Audio src={audio} />,
    writing: <TextEditor />,
    speaking: <Speaking />,
    upload: <Upload />,
    default: <Text content={"no data"} />,
  };
  return (
    <div
      className={`p-3 w-full ${!isFull ? "w- 1/2" : "w- full"}`}
      onClick={() => console.log("block_id", blockId)}
    >
      <div
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        <span
          className={`relative z-0 inline-flex shadow-sm rounded-md
            
            ease-in-out duration-200 ${
              show
                ? "transform translate-y-0 opacity-100 "
                : "transform translate-y-full opacity-0"
            }`}
        >
          <button
            type="button"
            className="relative inline-flex items-center bg-blue-500 px-4 py-2 rounded-l-md border border-gray-300text-sm leading-5 font-medium text-white  focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          >
            Change block
          </button>
          <button
            type="button"
            onClick={deleteBlock}
            className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300  text-sm leading-5 font-medium text-white  focus:z-10 focus:outline-none focus:border-blue-300  bg-red-500 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          >
            Delete
          </button>
          <button
            type="button"
            onClick={updateWidth}
            className="-ml-px relative inline-flex items-center bg-green-500 px-4 py-2 rounded-r-md border border-gray-300 text-sm leading-5 font-medium text-white focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
          >
            Change width
          </button>
        </span>

        <div>
          {dynamicMapper[!isStatic ? get(data, "block_by_pk.type") : type]}
        </div>
      </div>
    </div>
  );
};
export default BlockView;
