import gql from "graphql-tag";
import { quiz, image, text, audio } from "./fragments";
import { BLOCK_FIELDS } from "../fragments/block";
export const BLOCK_CREATE = gql`
  mutation insert_block_one($object: block_insert_input!) {
    insert_block_one(object: $object) {
      ...BLOCK_FIELDS
    }
  }
  ${BLOCK_FIELDS}
`;

export const BLOCK_DELETE = gql`
  mutation deleteBlock($where: BlockWhereInput) {
    deleteBlock(where: $where) {
      id
      name
      type
      ${quiz}
      ${image}
      ${text}
      ${audio}
    }
  }
`;

export default {
  "block.create": BLOCK_CREATE,
  "block.delete": BLOCK_DELETE,
};
