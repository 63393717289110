import gql from "graphql-tag";
import { LEVEL_FIELDS } from "../fragments/level";
export const LEVEL_CREATE = gql`
  mutation insert_level_one($object: level_insert_input!) {
    insert_level_one(object: $object) {
      ...LEVEL_FIELDS
    }
  }
  ${LEVEL_FIELDS}
`;

export const LEVEL_UPDATE = gql`
  mutation update_level_by_pk(
    $pk_columns: level_pk_columns_input!
    $_set: level_set_input
  ) {
    update_level_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...LEVEL_FIELDS
    }
  }
  ${LEVEL_FIELDS}
`;

export const LEVEL_DELETE = gql`
  mutation delete_level_by_pk($id: uuid!) {
    delete_level_by_pk(id: $id) {
      ...LEVEL_FIELDS
    }
  }
  ${LEVEL_FIELDS}
`;

const level_mutations = {
  "level.create": LEVEL_CREATE,
  "level.update": LEVEL_UPDATE,
  "level.delete": LEVEL_DELETE,
};

export default level_mutations
