import { useEffect, useState, useRef } from "react";
import {
    PlayIcon,
    PauseIcon,
  } from "@heroicons/react/solid";
export function AudioPreview({ src }) {
  const [playing, setPlaying] = useState(false);
  // const [path, setPath] = useState("M4 4l12 6-12 6z");

  const audioPlayer = useRef(null);

  useEffect(() => {
    audioPlayer.current.src = src;
  }, [src]);

  const playAudio = () => {
    setPlaying(true);
    // setPath("M5 4h3v12H5V4zm7 0h3v12h-3V4z");

    var promise = audioPlayer.current.play();
    if (promise !== undefined) {
      promise
        .catch((error) => {
          console.log("error");
          // Auto-play was prevented
          // Show a UI element to let the user manually start playback
        })
        .then(() => {
          // Auto-play started
        });
    }
  };

  const stopAudio = () => {
    setPlaying(false);
    // setPath("M4 4l12 6-12 6z");
    audioPlayer.current.pause();
  };

  return (
    <>
      <audio id="audio" ref={audioPlayer}>
        Your browser does not support the <code>audio</code> element.
      </audio>

      <button
        type="button"
        title="Play Audio"
        onClick={playing ? stopAudio : playAudio}
        className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        {playing ? (
          <PauseIcon className="h-4 w-4" aria-hidden="true" />
        ) : (
          <PlayIcon className="h-4 w-4" aria-hidden="true" />
        )}
      </button>
    </>
  );
}
