import gql from "graphql-tag";
import { QUIZ_FIELDS } from "../fragments/quiz";
export const QUIZ_GET_MANY = gql`
  query quiz(
    $limit: Int
    $offset: Int
    $where: quiz_bool_exp
    $order_by: [quiz_order_by!]
  ) {
    quiz(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
      ...QUIZ_FIELDS
    }
  }
  ${QUIZ_FIELDS}
`;

export const QUIZ_GET_ONE = gql`
  query quiz_by_pk($id: uuid!) {
    quiz_by_pk(id: $id) {
      ...QUIZ_FIELDS
    }
  }
  ${QUIZ_FIELDS}
`;


export const QUIZ_COUNT = gql`
  query quiz_aggregate {
    quiz_aggregate {
      aggregate {
        count
      }
    }
  }
`;


const quiz_queries = {
  "quiz.get.one": QUIZ_GET_ONE,
  "quiz.get.many": QUIZ_GET_MANY,
  "quiz.count": QUIZ_COUNT
};

export default quiz_queries;
