import { useLocation } from "react-router-dom";
import get from "lodash/get";
import { useQuery } from "services/Client";
export default function useBreadcrumbs() {
  let location = useLocation();

  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  const paths = location.pathname.split("/");
  paths.shift();

  function toObject(arr) {
    var rv = [];
    for (var i = 0; i < paths.length; ++i)
      if (regexExp.test(arr[i + 1])) rv.push({ [arr[i]]: arr[i + 1] });
    return rv;
  }
  const vars = toObject(paths);
  //   getTest({ variables: { id: vars["tests}"] } });

  const courseId = get(vars, "[0].courses");
  const levelId = get(vars, "[1].levels");
  const unitId = get(vars, "[2].units");
  const lessonId = get(vars, "[3].lessons");
  const activityId = get(vars, "[4].activities");

  const { data: courseData } = useQuery({
    event: "course.get.one",
    variables: { id: courseId },
    skip: !courseId,
  });

  const { data: levelData } = useQuery({
    event: "level.get.one",
    variables: { id: levelId },
    skip: !levelId,
  });

  const { data: unitData } = useQuery({
    event: "unit.get.one",
    variables: { id: unitId },
    skip: !unitId,
  });

  const { data: lessonData } = useQuery({
    event: "lesson.get.one",
    variables: { id: lessonId },
    skip: !lessonId,
  });

  const { data: activityData } = useQuery({
    event: "activity.get.one",
    variables: { id: activityId },
    skip: !activityId,
  });

  return [
    {
      title: get(courseData, "course_by_pk.title"),
      id: get(courseData, "course_by_pk.id"),
      path: `courses/${get(courseData, "course_by_pk.id")}/levels`,
    },
    {
      title: get(levelData, "level_by_pk.name"),
      id: get(levelData, "level_by_pk.id"),
      path: `courses/${get(courseData, "course_by_pk.id")}/levels/${get(
        levelData,
        "level_by_pk.id"
      )}/units`,
    },
    {
      title: get(unitData, "unit_by_pk.name"),
      id: get(unitData, "unit_by_pk.id"),
      path: `courses/${get(courseData, "course_by_pk.id")}/levels/${get(
        levelData,
        "level_by_pk.id"
      )}/units/${get(unitData, "unit_by_pk.id")}/lessons`,
    },
    {
      title: get(lessonData, "lesson_by_pk.name"),
      id: get(lessonData, "lesson_by_pk.id"),
      path: `courses/${get(courseData, "course_by_pk.id")}/levels/${get(
        levelData,
        "level_by_pk.id"
      )}/units/${get(unitData, "unit_by_pk.id")}/lessons/${get(
        lessonData,
        "lesson_by_pk.id"
      )}/${get(lessonData, "lesson_by_pk.examIs")}/activities`,
    },
    {
      title: `${get(activityData, "activity_by_pk.name")} Builder`,
      id: get(activityData, "activity_by_pk.id"),
      path: `courses/${get(courseData, "course_by_pk.id")}/levels/${get(
        levelData,
        "level_by_pk.id"
      )}/units/${get(unitData, "unit_by_pk.id")}/lessons/${get(
        lessonData,
        "lesson_by_pk.id"
      )}/${get(lessonData, "lesson_by_pk.examId")}/activities/${get(
        activityData,
        "activity_by_pk.id"
      )}/builder`,
    },
  ];
}
