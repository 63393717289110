import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import { useNavigate, useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import withStore from "services/Store";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Table({
  columns,
  data,
  pageCount: controlledPageCount,
  setters,
  hiddenColumns = [],
  canClickRow = true,
  onRowClick = () => false,
}) {
  // Use the state and functions returned from useTable to build your UI
  function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryParams();
  const pageIndex1 = parseInt(query.get("page")) || 0;

  const node = React.useRef();

  function DefaultColumnFilter({ column }) {
    return (
      <input
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        type="text"
        onChange={(e) => {
          setters[column.getHeaderProps().key](`${e.target.value}`);
        }}
        placeholder={`Search records...`}
      />
    );
  }
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setPageSize,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      manualPagination: true, // Tell the usePagination
      initialState: { pageIndex: pageIndex1, hiddenColumns }, // Pass our hoisted table state
      pageCount: Math.ceil(controlledPageCount),
    },
    usePagination
  );
  let navigate = useNavigate();

  // Render the UI for your table
  useEffect(() => {
    navigate({
      search: `?page=${pageIndex}&pageSize=${pageSize}`,
    });
  }, [pageIndex, navigate, pageSize]);

  return (
    <div ref={node}>
      <table className="min-w-full" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                  <div className="mt-1">
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white" {...getTableBodyProps()}>
          <AnimatePresence>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <motion.tr
                  {...row.getRowProps({
                    layoutTransition: "spring",
                    exit: { opacity: 0, maxHeight: 0 },
                  })}
                  className={classNames(
                    canClickRow ? "cursor-pointer hover:shadow-lg" : ""
                  )}
                  onClick={() => canClickRow && onRowClick(row.original)}
                >
                  {row.cells.map((cell) => {
                    return (
                      <motion.td
                        className="px-6 py-4 whitespace-no-wrap text-sm border-b border-gray-200"
                        {...cell.getCellProps({
                          layoutTransition: "spring",
                        })}
                      >
                        {cell.render("Cell")}
                      </motion.td>
                    );
                  })}
                </motion.tr>
              );
            })}
          </AnimatePresence>
        </tbody>
      </table>
      <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
        <div className="hidden sm:block">
          <p className="text-sm leading-5 text-gray-700">
            Showing
            <span className="font-medium"> {pageIndex * pageSize + 1} </span>
            to
            <span className="font-medium">
              {" "}
              {pageSize * pageIndex + pageSize}{" "}
            </span>
            of
            <span className="font-medium">
              {" "}
              {controlledPageCount * pageSize}{" "}
            </span>
            results
          </p>
        </div>

        <div>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
            aria-label="Selected tab"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
          >
            <option value={5}>5 rows</option>
            <option value={10}>10 rows</option>
            <option value={20}>20 rows</option>
            <option value={50}>50 rows</option>
          </select>
        </div>

        <div className="flex justify-between sm:justify-end">
          <button
            onClick={() => previousPage()}
            className={`relative inline-flex ${
              !canPreviousPage && "cursor-not-allowed"
            } items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white ${
              canPreviousPage && "hover:text-gray-500"
            } focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            className={`ml-3 relative inline-flex ${
              !canNextPage && "cursor-not-allowed"
            } disabled:cursor-not-allowed items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white ${
              canNextPage && "hover:text-gray-500"
            } focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default withStore(Table);
