import { gql } from "@apollo/client";

export const MEDIA_FIELDS = gql`
  fragment MEDIA_FIELDS on media {
    id
    label
    type
    path
  }
`;
