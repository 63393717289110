import { gql } from "@apollo/client";

export const LESSON_FIELDS = gql`
  fragment LESSON_FIELDS on lesson {
    id
    name
    order
    examId
  }
`;
