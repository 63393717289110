import gql from "graphql-tag";
import { QUESTION_OPTION_FIELDS } from "../fragments/question_option";

export const QUESTIONOPTION_CREATE = gql`
  mutation insert_question_option_one($object: question_option_insert_input!) {
    insert_question_option_one(object: $object) {
      ...QUESTION_OPTION_FIELDS
    }
  }
  ${QUESTION_OPTION_FIELDS}
`;

export const QUESTIONOPTION_UPDATE = gql`
  mutation update_question_option_by_pk(
    $pk_columns: question_option_pk_columns_input!
    $_set: question_option_set_input
  ) {
    update_question_option_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...QUESTION_OPTION_FIELDS
    }
  }
  ${QUESTION_OPTION_FIELDS}
`;

export const QUESTIONOPTION_DELETE = gql`
  mutation delete_question_option_by_pk($id: uuid!) {
    delete_question_option_by_pk(id: $id) {
      ...QUESTION_OPTION_FIELDS
    }
  }
  ${QUESTION_OPTION_FIELDS}
`;

const question_option_mutations = {
  "questionOption.create": QUESTIONOPTION_CREATE,
  "questionOption.update": QUESTIONOPTION_UPDATE,
  "questionOption.delete": QUESTIONOPTION_DELETE,
};

export default question_option_mutations;
