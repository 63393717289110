import gql from "graphql-tag";
import { DND_FIELDS } from "../fragments/dnd";
export const DND_GET_ONE = gql`
  query dnd_by_pk($id: uuid!) {
    dnd_by_pk(id: $id) {
      ...DND_FIELDS
    }
  }
  ${DND_FIELDS}
`;



const dnd_queries = {
  "dnd.get.one": DND_GET_ONE,
};

export default dnd_queries;
