import React from "react";
import { NavLink, Route, Routes, Navigate } from "react-router-dom";
import routes from "./routes";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  return (
    <div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex">
            {routes.map((route) => {
              return (
                !route.invisible && (
                  <NavLink
                    key={route.name}
                    to={route.path}
                    className={({ isActive }) =>
                      classNames(
                        isActive
                          ? "border-primary-500 text-primary-600 focus:text-primary-800 focus:border-primary-700 hover:border-primary-300"
                          : "border-transparent font-medium text-sm leading-5 text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300",
                        "w-1/4 py-4 px-1 text-center border-b-2 "
                      )
                    }
                  >
                    {route.name}
                  </NavLink>
                )
              );
            })}
          </nav>
        </div>
      </div>
      <div className="pt-10">
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={<route.component />}
            />
          ))}
          <Route
            index
            element={
              <>
                <Navigate to="all" />
              </>
            }
          />
        </Routes>
      </div>
    </div>
  );
}
