import React from "react";
import { object, string, number } from "yup";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import useCreateLevel from "./useCreateLevel";
import CreateOrEditLevel from "../shared/CreateOrEditLevel";
export default function CreateOneLevel() {
  let navigate = useNavigate();
  const { id: courseId } = useParams();
  const insertLevel = useCreateLevel(courseId);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: "",
        order: "",
        overview: "",
        passingScore: 0
      }}
      onSubmit={(values) =>
        insertLevel({
          variables: {
            object: { ...values, courseId },
          },
        })
      }
      validationSchema={object({
        name: string().min(5, "error").required("error"),
        order: number().required("error").positive().integer(),
        passingScore: number().required("error").positive().integer(),
        overview: string().required("error"),
      })}
    >
      {({ handleChange, values: { overview } }) => (
        <Form>
          <CreateOrEditLevel
            onChange={handleChange}
            overview={overview}
            onCancel={() => navigate(-1)}
          />
        </Form>
      )}
    </Formik>
  );
}
