import React from "react";
import { useQuery, useMutation } from "services/Client";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import View from "./view";
import useCreateQuestion from "./hooks/useCreateQuestion";
import useCreateQuestionOption from "./hooks/useCreateQuestionOption";
import useDeleteQuestion from "./hooks/useDeleteQuestion";
import useDeleteQuestionOption from "./hooks/useDeleteQuestionOption";
import useUploadFile from "shared/helpers/useUploadFile";
export default function EditDnd() {
  const { id } = useParams();

  const { uploadFile, progress } = useUploadFile();

  const { data: quizData } = useQuery({
    event: "dnd.get.one",
    variables: { id },
  });

  const title = get(quizData, "dnd_by_pk.title");

  const { data, refetch } = useQuery({
    event: "question.get.many",
    variables: {
      where: { dndId: { _eq: id } },
      order_by: { order: "asc" },
    },
  });

  const questions = get(data, "question", []);

  const { data: questionOptionData } = useQuery({
    event: "questionOption.get.many",
    variables: {
      where: { dndId: { _eq: id } },
      order_by: { order: "asc" },
    },
    skip: !id,
  });
  const options = get(questionOptionData, "question_option", []);

  const { mutate: updateFillBlank } = useMutation({
    event: "dnd.update",
  });
  const createQuestion = useCreateQuestion(id);
  const createQuestionOption = useCreateQuestionOption();
  const deleteQuestion = useDeleteQuestion(id);
  const deleteQuestionOption = useDeleteQuestionOption(refetch);
  // const createQuestionOption = useCreateQuestionOption(questionId, data);

  const { mutate: updateQuestion } = useMutation({ event: "question.update" });
  const { mutate: updateQuestionOption } = useMutation({
    event: "questionOption.update",
  });

  const handleUpdateDnd = (mutate) => (title) => {
    mutate({
      variables: { pk_columns: { id }, _set: { title } },
    });
  };

  const handleUpdateQuestion = (mutate) => (id, data) => {
    if (id) {
      mutate({
        variables: { pk_columns: { id }, _set: { ...data } },
      });
    }
  };

  const handleUpdateQuestionOption = (mutate) => (id, data) => {
    if (id) {
      mutate({
        variables: { pk_columns: { id }, _set: { ...data } },
      });
    }
  };

  const handleCreateQuestion = (mutate) => (file, cb) => {
    uploadFile({
      file,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
    }).then((path) =>
      mutate({
        variables: {
          object: {
            label: file.name,
            order: (questions.length + 1).toString(),
            media: {
              data: {
                label: file.name,
                path,
              },
            },
            dndId: id,
          },
        },
      }).then(() => cb())
    );
  };

  const handleCreateQuestionOption = (mutate) => (label, cb) => {
    mutate({
      variables: {
        object: {
          label,
          order: (options.length + 1).toString(),
          dndId: id,
        },
      },
    }).then(() => cb());
  };

  const handleDeleteQuestion = (mutate) => (id) => {
    if (id) {
      mutate({
        variables: { id },
      });
    }
  };

  const handleDeleteQuestionOption = (mutate) => (id) => {
    if (id) {
      mutate({
        variables: { id },
      });
    }
  };

  console.log(questions);

  return (
    <>
      <View
        data={{ questions, title, options, progress }}
        mutations={{
          handleUpdateQuestion: handleUpdateQuestion(updateQuestion),
          handleUpdateQuestionOption:
            handleUpdateQuestionOption(updateQuestionOption),
          handleCreateQuestion: handleCreateQuestion(createQuestion),
          handleCreateQuestionOption:
            handleCreateQuestionOption(createQuestionOption),
          handleDeleteQuestion: handleDeleteQuestion(deleteQuestion),
          handleDeleteQuestionOption:
            handleDeleteQuestionOption(deleteQuestionOption),
          handleUpdateDnd: handleUpdateDnd(updateFillBlank),
        }}
      />
    </>
  );
}
