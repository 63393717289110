import get from "lodash/get";
import gql from "graphql-tag";
import { useMutation } from "services/Client";
import { useNavigate } from "react-router-dom";

import { useNotification } from "hooks";
import { BLOCK_LIST_FIELDS } from "services/Client/schemas/fragments/block_list_item";
export default function useCreateBlockListItem(types, goToBlockEdit) {
  const notification = useNotification();
  let navigate = useNavigate();

  const { mutate } = useMutation({
    event: "block.list.item.create",
    update: (data, cache) => {
      cache.modify({
        fields: {
          block_list_item(existing = []) {
            const newRef = cache.writeFragment({
              data: data.data.insert_block_list_item_one,
              fragmentName: "NewBlockListItem",
              fragment: gql`
                fragment NewBlockListItem on block_list_item {
                  ...BLOCK_LIST_FIELDS
                }
                ${BLOCK_LIST_FIELDS}
              `,
            });
            // const toto = existingUsers.slice(0)
            // toto.pop()
            return [newRef, ...existing];
          },
          block_list_item_aggregate(oldData) {
            return {
              aggregate: {
                count: oldData.aggregate.count + 1,
              },
            };
          },
        },
      });
    },
    onCompleted: (results) => {
      const type = get(results, "insert_block_list_item_one.type");
      if (type === "media") {
        notification.success({
          message: "Media Saved",
        });
      } else {
        notification.success({
          buttonLabel: `Edit ${types[type]}`,
          buttonAction: () =>
            navigate(`../${goToBlockEdit(results.insert_block_list_item_one)}`),
          message: `${types[type]} Saved`,
        });
      }
    },
  });
  return mutate;
}
