import gql from "graphql-tag";
import { QUIZ_FIELDS } from "../fragments/quiz";

export const QUIZ_CREATE = gql`
  mutation insert_quiz_one($object: quiz_insert_input!) {
    insert_quiz_one(object: $object) {
      ...QUIZ_FIELDS
    }
  }
  ${QUIZ_FIELDS}
`;

export const QUIZ_UPDATE = gql`
  mutation update_quiz_by_pk(
    $pk_columns: quiz_pk_columns_input!
    $_set: quiz_set_input
  ) {
    update_quiz_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...QUIZ_FIELDS
    }
  }
  ${QUIZ_FIELDS}
`;

export const QUIZ_DELETE = gql`
  mutation delete_quiz_by_pk($id: uuid!) {
    delete_quiz_by_pk(id: $id) {
      ...QUIZ_FIELDS
    }
  }
  ${QUIZ_FIELDS}
`;

export const QUIZ_GRADE = gql`
  mutation gradeQuiz($where: QuizWhereInput) {
    gradeQuiz(where: $where)
  }
`;

const quiz_mutations = {
  "quiz.create": QUIZ_CREATE,
  "quiz.update": QUIZ_UPDATE,
  "quiz.delete": QUIZ_DELETE,
  "quiz.grade": QUIZ_GRADE,
};

export default quiz_mutations;
