import React from "react";
import get from "lodash/get";
import View from "./view";
export default function Dnd({ dnd }) {
  const title = get(dnd, "title");

  const questions = get(dnd, "questions", []);

  const options = get(dnd, "question_options", []);

  console.log(dnd)

  return (
    <>
      <View data={{ questions, title, options }} />
    </>
  );
}
