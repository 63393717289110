import React from "react";
import CardN from "shared/components/CardN";
import AddButton from "shared/components/AddButton";
import { useNavigate } from "react-router-dom";
import CardNSkeleton from "shared/components/CardNSkeleton";
export default function ListView({ data, handleCreate, handleDelete }) {
  let navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="grid grid-cols-4 gap-5">
          {true ? (
            <>
              {data.map((activity) => (
                <CardN
                  key={activity.id}
                  title={`${activity.order} - ${activity.name}`}
                  subTitle={activity.examId ? "Exam" : ""}
                  onEdit={() => navigate(`${activity.id}`)}
                  onAdd={() =>
                    navigate({
                      pathname: `${activity.id}/builder`,
                    })
                  }
                  onDelete={() => handleDelete(activity.id)}
                />
              ))}
              <AddButton handleClick={handleCreate} />
            </>
          ) : (
            <>
              {[1, 2, 3, 4, 5, 6].map((key) => (
                <CardNSkeleton key={key} />
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
