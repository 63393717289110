import React from "react";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import useCreateUser from "./useCreateUser";
import View from "./view";
export default function CreateUser() {
  const CreateUserSchema = object().shape({
    first_name: string().required("error"),
    last_name: string().required("error"),
    email: string().email("error").required("error"),
  });

  const insertUser = useCreateUser();
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        first_name: "",
        last_name: "",
        email: "",
        role: "user",
      }}
      onSubmit={(values) =>
        insertUser({
          variables: {
            object: { ...values },
          },
        })
      }
      validationSchema={CreateUserSchema}
    >
      {({ handleChange, values: { role } }) => (
        <Form>
          <View role={role} onChange={handleChange} />
        </Form>
      )}
    </Formik>
  );
}
