import React from "react";
import TeX from "@matejmazur/react-katex";

export default function QuizOption({
  option,
  index,
  focus,
  setObj,
  questionId,
  math,
}) {
  const color = focus
    ? "bg-blue-600 text-white"
    : "";

  return (
    <div
      className="flex hover:shadow-lg cursor-pointer ml-2 mr-2 mb-3"
      onClick={() => setObj({ questionId, answerId: option.id })}
    >
      <div className="flex items-center flex-no-wrap my-3">
        <div
          className={`text-xs flex-shrink-0 rounded-full border border-gray-300 h-8 w-8 flex items-center justify-center mx-2 ${color}`}
        >
          {index + 1}
        </div>
        <div>
          {math ? (
            <TeX>{`${option.label}`}</TeX>
          ) : (
            <p className="font-medium text-md">{option.label} </p>
          )}
        </div>
      </div>
    </div>
  );
}
