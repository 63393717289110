import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { useGcal } from "@geerdsolutions/gcal";

import { cache as ClientCache } from "services/Client";

const { cache } = ClientCache;

const errorLink = onError(
  ({ graphQLErrors, networkError, forward, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(
        ({ message, extensions, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extension: ${extensions.code}`
          )
        // forward(operation)
      );
    }

    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      // forward(operation);
      // window.location.reload();
      // localStorage.clear()
    }
    forward(operation);
  }
);

const Apollo = (App) =>
  function Apolloed() {
    const { getSession } = useGcal();
    const withToken = setContext(async (_, { headers }) => {
      const session = await getSession();
      const token = session.getAccessToken().getJwtToken();
      return {
        headers: {
          ...headers,
          ...(token && { Authorization: `Bearer ${token}` }),
        },
      };
    });
    const httpLink = createHttpLink({
      uri: process.env.REACT_APP_BACKEND_URI,
    });
    const client = new ApolloClient({
      link: from([errorLink, withToken, httpLink]),
      cache,
    });
    return (
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    );
  };

export default Apollo;
