import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { ACTIVITY_GET_MANY } from "services/Client/schemas/queries/activity";

export default function useCreateActivity(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "activity.create",
    update: ({ data: { insert_activity_one } }, cache) => {
      const prevData = cache.readQuery({
        query: ACTIVITY_GET_MANY,
        variables: {
          where: { lessonId: { _eq: id } }
        },
      });
      const data = {
        activity: [insert_activity_one, ...prevData.activity],
      };
      cache.writeQuery({
        query: ACTIVITY_GET_MANY,
        data,
        variables: {
          where: { lessonId: { _eq: id } }
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Activity Saved",
      });
    },
  });
  return mutate;
}
