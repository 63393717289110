import { useMutation } from "services/Client";

import { QUESTION_GET_MANY } from "services/Client/schemas/queries/question";
export default function useCreateQuestion(id) {
  const { mutate } = useMutation({
    event: "question.create",
    update: ({ data: { insert_question_one } }, cache) => {
      const prevData = cache.readQuery({
        query: QUESTION_GET_MANY,
        variables: {
          where: { quizId: { _eq: id } },
          order_by: { order: "asc" },
        },
      });
      const data = {
        question: [...prevData.question, insert_question_one],
      };
      cache.writeQuery({
        query: QUESTION_GET_MANY,
        data,
        variables: {
          where: { quizId: { _eq: id } },
          order_by: { order: "asc" },
        },
      });
    },
  });
  return mutate;
}
