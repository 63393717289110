import React from "react";
import { cache as ClientCache } from "services/Client";
const { notificationVar } = ClientCache;
const NotificationManager = (App) => {
  const withNotification = ({ ...rest }) => {
    const error = (message) => {
      notificationVar({
        message,
        type: "danger",
        opened: true,
        __typename: "notification",
      });
    };

    const success = (message) => {
      notificationVar({
        message,
        type: "success",
        opened: true,
        __typename: "notification",
      });
    };

    const warning = (message) => {
      notificationVar({
        message,
        type: "warning",
        opened: true,
        __typename: "notification",
      });
    };

    const notification = {
      error,
      success,
      warning,
    };
    return <App {...rest} notification={notification} />;
  };

  return withNotification;
};

export default NotificationManager;
