import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { PROGRESSION_GET_MANY } from "services/Client/schemas/queries/progression";
export default function useCreateProgression(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "progression.create",
    update: ({ data: { insert_progression } }, cache) => {
      const prevData = cache.readQuery({
        query: PROGRESSION_GET_MANY,
        variables: {
          where: { userId: { _eq: id } }
        },
      });
      const data = {
        progression: [...insert_progression.returning, ...prevData.progression],
      };
      cache.writeQuery({
        query: PROGRESSION_GET_MANY,
        data,
        variables: {
          where: { userId: { _eq: id } }
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Progression Saved",
      });
    },
  });
  return mutate;
}
