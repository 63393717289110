import userActivity from "./userActivity";
import file from "./file";
import user from "./user";
import feedback from "./feedback";
import level from "./level";
import unit from "./unit";
import lesson from "./lesson";

import activity from "./activity";
import quiz from "./quiz";
import question from "./question";
import questionOption from "./questionOption";
import text from "./text";
import media from "./media";
import image from "./image";
import block from "./block";
import report from "./report";
import activity_layout_mutations from "./activity_layout";
import course from "./course";
import fill_blank from "./fill_blank";
import block_list_item from "./block_list_item";
import dnd from "./dnd";
import progression from "./progression";

const mutations = {
  ...feedback,
  ...user,
  ...course,
  ...userActivity,
  ...file,
  ...fill_blank,
  ...block_list_item,
  ...dnd,
  ...progression,
  ...level,
  ...unit,
  ...lesson,
  ...activity,
  ...quiz,
  ...question,
  ...questionOption,
  ...text,
  ...media,
  ...image,
  ...block,
  ...report,
  ...activity_layout_mutations,
};

export default mutations;
