import gql from "graphql-tag";
import { TEXT_FIELDS } from "../fragments/text";
export const TEXT_CREATE = gql`
  mutation insert_text_one($object: text_insert_input!) {
    insert_text_one(object: $object) {
      ...TEXT_FIELDS
    }
  }
  ${TEXT_FIELDS}
`;

export const TEXT_UPDATE = gql`
  mutation update_text_by_pk(
    $pk_columns: text_pk_columns_input!
    $_set: text_set_input
  ) {
    update_text_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...TEXT_FIELDS
    }
  }
  ${TEXT_FIELDS}
`;

export const TEXT_DELETE = gql`
  mutation delete_text_by_pk($id: uuid!) {
    delete_text_by_pk(id: $id) {
      ...TEXT_FIELDS
    }
  }
  ${TEXT_FIELDS}
`;

const text_mutations = {
  "text.create": TEXT_CREATE,
  "text.update": TEXT_UPDATE,
  "text.delete": TEXT_DELETE,
};

export default text_mutations;
