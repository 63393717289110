import React from "react";
import get from "lodash/get";
import { object, string, number } from "yup";
import { Formik, Form } from "formik";
import { useQuery, useMutation } from "services/Client";
import { useParams, useNavigate } from "react-router-dom";
import { useNotification } from "hooks";

import CreateOrEditActivity from "../shared/CreateOrEditActivity";
export default function All() {
  const { id, examId } = useParams();
  const notification = useNotification();

  let navigate = useNavigate();
  const { data } = useQuery({
    event: "activity.get.one",
    variables: {
      id,
    },
    skip: !id,
  });
  const activity = get(data, "activity_by_pk", {
    name: "",
    order: 0,
    examId: null,
  });

  const { mutate: updateActivity } = useMutation({
    event: "activity.update",
    onCompleted: () => {
      notification.success({
        message: "Activity Saved",
      });
      navigate(-1);
    },
  });

  const submit = (values) => {
    const { exam, ...rest } = values;
    if (exam) {
      updateActivity({
        variables: {
          _set: {
            ...rest,
            examId,
          },
          pk_columns: { id },
        },
      });
    } else {
      updateActivity({
        variables: {
          _set: {
            ...rest,
            examId: null,
          },
          pk_columns: { id },
        },
      });
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: activity.name,
        order: activity.order,
        exam: !!activity.examId,
      }}
      onSubmit={(values) => submit(values)}
      validationSchema={object({
        name: string().min(5, "error").required("error"),
        order: number().required("error").positive().integer(),
      })}
    >
      {({ handleChange, values: { exam } }) => (
        <Form>
          <CreateOrEditActivity
            onChange={handleChange}
            exam={exam}
            onCancel={() => navigate(-1)}
          />
        </Form>
      )}
    </Formik>
  );
}
