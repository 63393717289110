import gql from "graphql-tag";
import { COURSE_FIELDS } from "../fragments/course";
export const COURSE_GET_ONE = gql`
  query course_by_pk($id: uuid!) {
    course_by_pk(id: $id) {
      ...COURSE_FIELDS
    }
  }
  ${COURSE_FIELDS}
`;

export const COURSE_GET_MANY = gql`
  query course(
    $limit: Int
    $offset: Int
    $where: course_bool_exp
    $order_by: [course_order_by!]
  ) {
    course(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      ...COURSE_FIELDS
    }
  }
  ${COURSE_FIELDS}
`;

export const COURSE_COUNT = gql`
  query course_aggregate {
    course_aggregate {
      aggregate {
        count
      }
    }
  }
`;


const course_queries = {
  "course.get.one": COURSE_GET_ONE,
  "course.count": COURSE_COUNT,
  "course.get.many": COURSE_GET_MANY,
};

export default course_queries;
