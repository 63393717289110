import React from "react";
import { object, string, number } from "yup";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import useCreateUnit from "./useCreateUnit";
import CreateOrEditUnit from "../shared/CreateOrEditUnit";
export default function CreateOneUnit() {
  let navigate = useNavigate();
  const { levelId } = useParams();
  const insertUnit = useCreateUnit(levelId);
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: "",
        order: "",
        passingScore: 0,
      }}
      onSubmit={(values) =>
        insertUnit({
          variables: {
            object: { ...values, levelId },
          },
        })
      }
      validationSchema={object({
        name: string().min(5, "error").required("error"),
        order: number().required("error").positive().integer(),
        passingScore: number().required("error").positive().integer(),
      })}
    >
      <Form>
        <CreateOrEditUnit onCancel={() => navigate(-1)} />
      </Form>
    </Formik>
  );
}
