import gql from "graphql-tag";
import { UNIT_FIELDS } from "../fragments/unit";
export const UNIT_GET_ONE = gql`
  query unit_by_pk($id: uuid!) {
    unit_by_pk(id: $id) {
      ...UNIT_FIELDS
    }
  }
  ${UNIT_FIELDS}
`;

export const UNIT_GET_MANY = gql`
  query unit($where: unit_bool_exp, $order_by: [unit_order_by!]) {
    unit(where: $where, order_by: $order_by) {
      ...UNIT_FIELDS
    }
  }
  ${UNIT_FIELDS}
`;

const unit_queries = {
  "unit.get.one": UNIT_GET_ONE,
  "unit.get.many": UNIT_GET_MANY,
};

export default unit_queries;
