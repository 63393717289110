import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import EditableField from "./components/EditableField";
import UploadImage from "./components/UploadImage";
// import Image from "blocks/Image";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function View(props) {
  const [choice, setChoice] = useState("");
  const { data, mutations } = props;

  const { questions, title, options, progress } = data;
  const {
    handleUpdateQuestion,
    handleCreateQuestion,
    handleCreateQuestionOption,
    handleDeleteQuestionOption,
    handleUpdateDnd,
    handleDeleteQuestion,
  } = mutations;
  function handleOnDragEnd(result) {
    handleUpdateQuestion(result.destination.droppableId, {
      correctAnswerId: result.draggableId,
    });
  }
  return (
    <div className="m-auto  p-3  bg-gray-50 rounded-lg overflow-hidden  shadow-lg">
      <div className="flex flex-row justify-between my-3 items-center">
        <EditableField
          initialValue={title}
          onSave={handleUpdateDnd}
          className="font-medium text-xl"
        />

        <div className="flex space-x-2">
          <input
            type="text"
            name="choice"
            id="choice"
            onChange={(e) => setChoice(e.target.value)}
            value={choice}
            className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
            placeholder="insert choice"
          />
          <button
            type="button"
            onClick={() =>
              handleCreateQuestionOption(choice, () => setChoice(""))
            }
            className="inline-flex mr-1 items-center px-2.5 py-1.5 border border-transparent text-xs leading-4 font-medium rounded text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition ease-in-out duration-150"
          >
            Save
          </button>
        </div>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="bg-white m-auto rounded overflow-hidden p-5 flex-col space-y-2">
          <Droppable droppableId="choices">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex space-x-2"
              >
                {options.map((option, index) => (
                  <Draggable
                    key={option.id}
                    draggableId={option.id}
                    index={index}
                  >
                    {(provided) => (
                      <span
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="inline-flex rounded-full items-center py-1.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                      >
                        {option.label}
                        <button
                          type="button"
                          onClick={() => handleDeleteQuestionOption(option.id)}
                          className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                        >
                          <span className="sr-only">Remove</span>
                          <svg
                            className="h-2 w-2"
                            stroke="currentColor"
                            fill="none"
                            viewBox="0 0 8 8"
                          >
                            <path
                              strokeLinecap="round"
                              strokeWidth="1.5"
                              d="M1 1l6 6m0-6L1 7"
                            />
                          </svg>
                        </button>
                      </span>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <div className="flex space-x-4">
            <UploadImage handleSave={handleCreateQuestion} uploadProgress={progress} />
            <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
              {questions.map((question) => (
                <li key={question.id} className="relative">
                  <div className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
                    <img
                      src={question.media.path}
                      alt=""
                      className="object-cover pointer-events-none group-hover:opacity-75"
                    />
                    {/* <button
                      type="button"
                      className="absolute inset-0 focus:outline-none"
                    >
                      <span className="sr-only">
                        View details for {file.title}
                      </span>
                    </button> */}
                  </div>
                  <Droppable droppableId={question.id}>
                    {(provided) => (
                      <span
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={classNames(
                          !question.correct_answer && "w-16 h-6",
                          "inline-flex rounded-full items-center mt-2 py-0.5 pl-2.5 pr-1 text-sm font-medium bg-indigo-100 text-indigo-700"
                        )}
                      >
                        {question.correct_answer?.label}
                        {question.correct_answer && (
                          <button
                            type="button"
                            onClick={() =>
                              handleUpdateQuestion(question.id, {
                                correctAnswerId: null,
                              })
                            }
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:outline-none focus:bg-indigo-500 focus:text-white"
                          >
                            <span className="sr-only">Remove</span>
                            <svg
                              className="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        )}
                        {provided.placeholder}
                      </span>
                    )}
                  </Droppable>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </DragDropContext>
      {/* <div className=" flex justify-center mt-4  ">
        <button
          type="button"
          className=" uppercase mb-4 items-center px-4 py-2 border border-black hover:border-primary-500 text-sm leading-5 font-medium  rounded-lg  text-black hover:text-white  bg-transparent hover:bg-primary-500  transition ease-in-out duration-150"
          onClick={onfinish}
        >
          Check answers
        </button>
      </div> */}
    </div>
  );
}
