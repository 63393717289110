import gql from "graphql-tag";
import { LESSON_FIELDS } from "../fragments/lesson";
export const LESSON_GET_ONE = gql`
  query lesson_by_pk($id: uuid!) {
    lesson_by_pk(id: $id) {
      ...LESSON_FIELDS
    }
  }
  ${LESSON_FIELDS}
`;

export const LESSON_GET_MANY = gql`
  query lesson($where: lesson_bool_exp, $order_by: [lesson_order_by!]) {
    lesson(where: $where, order_by: $order_by) {
      ...LESSON_FIELDS
    }
  }
  ${LESSON_FIELDS}
`;

const lesson_queries = {
  "lesson.get.one": LESSON_GET_ONE,
  "lesson.get.many": LESSON_GET_MANY,
};

export default lesson_queries;
