import React from "react";
import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Input({ field, form: { touched, errors }, ...props }) {
  return (
    <div className="mt-1 relative rounded-md shadow-sm">
      <input
        type="text"
        {...field}
        {...props}
        className={classNames(
          touched[field.name]
            ? errors[field.name]
              ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
              : "border-green-300 text-green-900 placeholder-green-300 focus:ring-green-500 focus:border-green-500"
            : "focus:ring-blue-500 focus:border-blue-500 shadow-sm border-gray-300",
          "block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
        )}
      />

      {touched[field.name] && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {errors[field.name] ? (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            <CheckCircleIcon
              className="h-5 w-5 text-green-500"
              aria-hidden="true"
            />
          )}
        </div>
      )}
    </div>
  );
}
