import { gql } from "@apollo/client";

export const QUESTION_FIELDS = gql`
  fragment QUESTION_FIELDS on question {
    id
    label
    hasMath
    correct_answer {
      label
    }
    media {
      id
      path
    }
    order
    createdAt
  }
`;
