import React, { useReducer } from "react";
import { useQuery, useMutation } from "services/Client";
import get from "lodash/get";
import { useParams } from "react-router-dom";
import View from "./view";
import useCreateQuestion from "./hooks/useCreateQuestion";
import useCreateQuestionOption from "./hooks/useCreateQuestionOption";
import useDeleteQuestion from "./hooks/useDeleteQuestion";
import useDeleteQuestionOption from "./hooks/useDeleteQuestionOption";
function reducer(state, action) {
  switch (action.type) {
    case "navigate":
      return {
        ...state,
        index:
          state.index + action.payload.direction > 0
            ? state.index + action.payload.direction
            : 0,
      };
    case "reset-navigate":
      return {
        ...state,
        index: 0,
      };
    case "loading":
      return {
        ...state,
        loading: !state.loading,
      };
    case "go-to-page":
      return {
        ...state,
        index: action.payload.index,
      };
    default:
      return state;
  }
}

function Quiz() {
  const [state, dispatch] = useReducer(reducer, {
    index: 0,
    loading: false,
  });
  const { id } = useParams();

  const { data: quizData } = useQuery({
    event: "quiz.get.one",
    variables: { id },
  });

  const title = get(quizData, "quiz_by_pk.title");

  const { data } = useQuery({
    event: "question.get.many",
    variables: {
      where: { quizId: { _eq: id } },
      order_by: { order: "asc" },
    },
  });

  const questions = get(data, "question", []);
  const question = get(questions, `${[state.index]}`, {});
  const length = questions.length;
  const questionId = get(question, "id");

  const { data: questionOptionData } = useQuery({
    event: "questionOption.get.many",
    variables: {
      where: { questionId: { _eq: questionId } },
      order_by: { order: "asc" },
    },
    skip: !questionId,
  });
  const options = get(questionOptionData, "question_option", []);

  const { mutate: updateQuiz } = useMutation({ event: "quiz.update" });
  const createQuestion = useCreateQuestion(id);
  const createQuestionOption = useCreateQuestionOption();
  const deleteQuestion = useDeleteQuestion(id, dispatch);
  const deleteQuestionOption = useDeleteQuestionOption();
  // const createQuestionOption = useCreateQuestionOption(questionId, data);

  const { mutate: updateQuestion } = useMutation({ event: "question.update" });
  const { mutate: updateQuestionOption } = useMutation({
    event: "questionOption.update",
  });

  const handleUpdateQuiz = (mutate) => (data) => {
    mutate({
      variables: { pk_columns: { id }, _set: { ...data } },
    });
  };

  const handleUpdateQuestion = (mutate) => (id, data) => {
    if (id) {
      mutate({
        variables: { pk_columns: { id }, _set: { ...data } },
      });
    }
  };

  const handleUpdateQuestionOption = (mutate) => (id, data) => {
    if (id) {
      mutate({
        variables: { pk_columns: { id }, _set: { ...data } },
      });
    }
  };

  const handleCreateQuestion = (mutate) => async () => {
    const result = await mutate({
      variables: {
        object: {
          label: "",
          order: (questions.length + 1).toString(),
          quizId: id,
        },
      },
    });
    if (get(result, "data.insert_question_one.id")) {
      dispatch({ type: "go-to-page", payload: { index: length } });
    }
  };

  const handleCreateQuestionOption = (mutate) => (data) => {
    mutate({
      variables: {
        object: {
          label: data.label,
          order: (options.length + 1).toString(),
          questionId,
        },
      },
    });
  };

  const handleDeleteQuestion = (mutate) => () => {
    if (id) {
      mutate({
        variables: { id: questionId },
      });
    }
  };

  const handleDeleteQuestionOption = (mutate) => (id) => {
    if (id) {
      mutate({
        variables: { id },
      });
    }
  };

  const { mutate: createImage } = useMutation({ event: "image.create" });

  const handleAddImage = (mutate) => async (file) => {
    dispatch({ type: "loading" });
    const result = await mutate({
      variables: {
        file,
        data: { name: file.name },
      },
    });
    const imageId = get(result, "data.createImage.id");
    if (imageId) {
      handleUpdateQuestion(updateQuestion)(question.id, {
        image: { id: imageId },
      });
    }
    // setFiles([]);
    dispatch({ type: "loading" });
  };

  return (
    <>
      <View
        data={{ question, title, options, length, ...state }}
        handler={dispatch}
        mutations={{
          handleUpdateQuestion: handleUpdateQuestion(updateQuestion),
          handleUpdateQuestionOption:
            handleUpdateQuestionOption(updateQuestionOption),
          handleCreateQuestion: handleCreateQuestion(createQuestion),
          handleCreateQuestionOption:
            handleCreateQuestionOption(createQuestionOption),
          handleDeleteQuestion: handleDeleteQuestion(deleteQuestion),
          handleDeleteQuestionOption:
            handleDeleteQuestionOption(deleteQuestionOption),
          handleAddImage: handleAddImage(createImage),
          handleUpdateQuiz: handleUpdateQuiz(updateQuiz),
        }}
      />
    </>
  );
}
export default Quiz;
