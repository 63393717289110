import gql from "graphql-tag";
import { FILL_BLANK_FIELDS } from "../fragments/fill_blank";

export const FILL_BLANK_GET_ONE = gql`
  query fill_blank_by_pk($id: uuid!) {
    fill_blank_by_pk(id: $id) {
      ...FILL_BLANK_FIELDS
    }
  }
  ${FILL_BLANK_FIELDS}
`;

const fill_blank_queries = {
  "fill.blank.get.one": FILL_BLANK_GET_ONE,
};

export default fill_blank_queries;
