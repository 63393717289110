import React, { useEffect, useReducer, useState } from "react";
import { useQuery } from "services/Client";
// import Global from "services/Global";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

import find from "lodash/find";
import useKeyPress from "./helpers/useKeyPress";
import Loading from "components/Loading";
import View from "./view";

function reducer(state, action) {
  switch (action.type) {
    case "add-missed-question":
      return {
        ...state,
        missedQuestions: [...state.missedQuestions, action.payload.index],
      };
    case "clear-missed-question":
      return {
        ...state,
        missedQuestions: [],
      };
    case "navigate":
      return {
        ...state,
        index: state.index + action.payload.direction,
      };
    case "go-to-missed":
      return {
        ...state,
        index: state.missedQuestions[state.missedQuestions.length - 1],
      };
    case "reset-navigate":
      return {
        ...state,
        index: 0,
      };
    default:
      return state;
  }
}

function Quiz({ quiz, onAnswer, questionsOrder }) {
  const [questions, setQuestions] = useState([]);
  const [state, dispatch] = useReducer(reducer, {
    missedQuestions: [],
    index: 0,
    leftQuestionsClick: 0,
  });

  const leftPress = useKeyPress("ArrowLeft");
  const rightPress = useKeyPress("ArrowRight");
  // const enterPress = useKeyPress("Enter");

  useEffect(() => {
    const non_sorted_questions = get(quiz, "questions", []);
    setQuestions(sortBy(non_sorted_questions, ["order"]));
  }, [quiz]);

  if (!questionsOrder) {
    questionsOrder = questions;
  }

  const question =
    find(questions, function (o) {
      return o.id === get(questionsOrder[state.index], "id");
    }) || get(questions, `${[state.index]}`, {});
  const length = questions.length;

  useEffect(() => {
    if (leftPress) {
      dispatch({ type: "navigate", payload: { direction: -1 } });
    }
  }, [leftPress]);

  useEffect(() => {
    if (rightPress) {
      dispatch({ type: "navigate", payload: { direction: 1 } });
    }
  }, [rightPress]);




  
  return (
    <>
      <View
        data={{ question, length, ...state }}
        onAnswer={onAnswer}
        handler={dispatch}
      />
    </>
  );
}
export default Quiz;
