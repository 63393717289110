import { useMutation } from "services/Client";
import { QUESTION_GET_MANY } from "services/Client/schemas/queries/question";
import { useNotification } from "hooks";

export default function useDeleteQuestion(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "question.delete",
    update: ({ data: { delete_question_by_pk } }, cache) => {
      const prevData = cache.readQuery({
        query: QUESTION_GET_MANY,
        variables: {
          where: { fillBlankId: { _eq: id } },
          order_by: { order: "asc" },
        },
      });

      const newData = prevData.question.filter(
        (question) => question.id !== delete_question_by_pk.id
      );
      const data = {
        question: [...newData],
      };
      cache.writeQuery({
        query: QUESTION_GET_MANY,
        data,
        variables: {
          where: { fillBlankId: { _eq: id } },
          order_by: { order: "asc" },
        },
      });
    },
    onCompleted: () => {

      notification.success({
        message: "Delete Successful",
      });
    },
  });
  return mutate;
}
