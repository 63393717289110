import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import routes from "./routes";

function Courses() {

  return (
    <div>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.name}
            path={route.path}
            element={<route.component />}
          />
        ))}
        <Route
          index
          element={
            <>
              <Navigate to="all?page=0&pageSize=7" />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default Courses;
