import gql from "graphql-tag";
import { USER_FIELDS } from "../fragments/user";

export const USER_INSERT_ONE = gql`
  mutation insert_user_one($object: user_insert_input!) {
    insert_user_one(object: $object) {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`;

export const USER_INSERT_MANY = gql`
  mutation insert_user($objects: [user_insert_input!]!) {
    insert_user(objects: $objects) {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`;

export const USER_UPDATE = gql`
  mutation update_user_by_pk(
    $pk_columns: user_pk_columns_input!
    $_set: user_set_input
  ) {
    update_user_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...USER_FIELDS
    }
  }
  ${USER_FIELDS}
`;


const user_mutations = {
  "user.insert.one": USER_INSERT_ONE,
  "user.insert.many": USER_INSERT_MANY,
  "user.update": USER_UPDATE,
};

export default user_mutations;
