import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { UNIT_GET_MANY } from "services/Client/schemas/queries/unit";
export default function useCreateUnit(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "unit.create",
    update: ({ data: { insert_unit_one } }, cache) => {
      const prevData = cache.readQuery({
        query: UNIT_GET_MANY,
        variables: {
          where: { levelId: { _eq: id } },
        },
      });
      const data = {
        unit: [insert_unit_one, ...prevData.unit],
      };
      cache.writeQuery({
        query: UNIT_GET_MANY,
        data,
        variables: {
          where: { levelId: { _eq: id } },
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Unit Saved",
      });
    },
  });
  return mutate;
}
