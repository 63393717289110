import gql from "graphql-tag";
import { BLOCK_TAG_FIELDS } from "../fragments/block_tag";
export const BLOCK_TAG_GET_MANY = gql`
  query block_tag(
    $limit: Int
    $offset: Int
    $where: block_tag_bool_exp
    $order_by: [block_tag_order_by!]
  ) {
    block_tag(
      limit: $limit
      offset: $offset
      where: $where
      order_by: $order_by
    ) {
      ...BLOCK_TAG_FIELDS
    }
  }
  ${BLOCK_TAG_FIELDS}
`;

const text_queries = {
  "block.tag.get.many": BLOCK_TAG_GET_MANY,
};

export default text_queries;
