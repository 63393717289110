import Dashboard from "screens/Dashboard";
import Users from "screens/Users";
import Courses from "screens/Courses";
import Blocks from "screens/Blocks";
// import Grade from "screens/Grade";
// import Report from "screens/Report";

var indexRoutes = [
  {
    path: "dashboard",
    link: "dashboard",
    name: "Home",
    component: Dashboard,
  },
  {
    path: "users/*",
    link: "users",
    name: "Users & Groups",
    component: Users,
  },
  {
    path: "courses/*",
    link: "courses",
    name: "Courses",
    component: Courses,
  },
  { path: "blocks/*", link: "blocks", name: "Blocks", component: Blocks },
];

export default indexRoutes;
