import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

function RichTextMarkdown({ onChange, value }) {
  return (
    <SunEditor
      height="350px"
      showToolbar={true}
      setOptions={{
        buttonList: [
          ["paragraphStyle", "blockquote", "formatBlock"],
          ["removeFormat"],
          ["undo", "redo"],
          ["outdent", "indent"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["align", "horizontalRule", "list", "lineHeight"],
          ["fullScreen", "showBlocks", "codeView"],
        ], // Or Array of button list, eg. [['font', 'align'], ['image']]
      }}
      setContents={value}
      onChange={onChange}
    />
  );
}

export default RichTextMarkdown;
