import gql from "graphql-tag";
import { COURSE_FIELDS } from "../fragments/course";
export const PLACEMENT_TEST_CREATE = gql`
  mutation insert_course_one($object: course_insert_input!) {
    insert_course_one(object: $object) {
      ...COURSE_FIELDS
    }
  }
  ${COURSE_FIELDS}
`;

export const PLACEMENT_TEST_UPDATE = gql`
  mutation update_course_by_pk(
    $pk_columns: course_pk_columns_input!
    $_set: course_set_input
  ) {
    update_course_by_pk(pk_columns: $pk_columns, _set: $_set) {
      ...COURSE_FIELDS
    }
  }
  ${COURSE_FIELDS}
`;

export const PLACEMENT_TEST_DELETE = gql`
  mutation delete_course_by_pk($id: uuid!) {
    delete_course_by_pk(id: $id) {
      ...COURSE_FIELDS
    }
  }
  ${COURSE_FIELDS}
`;

export const PLACEMENT_TEST_GRADE = gql`
  mutation gradePlacementTest(
    $where: PlacementTestWhereInput
    $user: UserWhereInput
    $activity: ActivityWhereInput
    $score: Int
  ) {
    gradePlacementTest(
      where: $where
      user: $user
      activity: $activity
      score: $score
    )
  }
`;

export const PLACEMENT_TEST_REPORT = gql`
  mutation placementTestReport($where: UserPlacementTestWhereInput) {
    placementTestReport(where: $where)
  }
`;

const placement_course_mutations = {
  "course.create": PLACEMENT_TEST_CREATE,
  "course.update": PLACEMENT_TEST_UPDATE,
  "course.delete": PLACEMENT_TEST_DELETE,
  "course.grade": PLACEMENT_TEST_GRADE,
  "course.report": PLACEMENT_TEST_REPORT,
};

export default placement_course_mutations
