import React, { useState } from "react";
import get from "lodash/get";
import { object, array, string } from "yup";
import { Formik, Form } from "formik";
import { useNotification } from "hooks";

import { useQuery, useMutation } from "services/Client";
import View from "./view";

function UploadUsers() {
  const notification = useNotification();
  const [userFromCSV, setuserFromCSV] = useState(null);


  const onUpload = (setFieldValue) => (users) => {
    const first_row = users[0];
    const list_users = users.slice(1);
    const final_users = list_users.map((item) => ({
      first_name: item.data[0],
      last_name: item.data[1],
      email: item.data[2],
    }));
    console.log(final_users);
    setFieldValue("users", final_users);
    // setFieldValue("users", get(result, "data.usersFromFile") || []);
  };

  const { mutate } = useMutation({
    event: "user.insert.many",
    onCompleted: (results) => {
      notification.success({
        message: "Candidates Saved",
      });
    },
  });
  const submit = (mutate, values) => {
    const users = values.users;
    const objects = users.map((user) => ({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      role: "user",
      user_groups: {
        data: values.groupIds.map((group) => ({ groupId: group.id })),
      },
    }));
    mutate({
      variables: {
        objects,
      },
    });
  };

  return (
    <Formik
      initialValues={{
        group: "",
        groupIds: [],
        users: [],
      }}
      onSubmit={(values) => submit(mutate, values)}
      validationSchema={object({
        groupIds: array()
          .min(1, "please choose one group at least")
          .required("error"),
        users: array()
          .of(
            object().shape({
              email: string()
                .ensure()
                .required("email error")
                .email("not an email"),
              first_name: string().required("first name error").min(1),
              last_name: string().required("laast name error").min(1),
            })
          )
          .min(1, "You need at least one user")
          .required("error"),
      })}
    >
      {({ values, errors, touched, handleBlur, setFieldValue }) => (
        <Form>
          <View
            formik={{
              values: { ...values, userFromCSV },
              errors: { ...errors },
              touched: { ...touched },
            }}
            handlers={{
              change: setFieldValue,
              setuserFromCSV,
              blur: handleBlur,
              onUpload: onUpload(setFieldValue),
            }}
          />
        </Form>
      )}
    </Formik>
  );
}

export default UploadUsers;
