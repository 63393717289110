import React, { Fragment } from "react";
import { addHocs } from "shared/helpers/Addhocs";
import { Notification } from "shared";
import { Route, Routes } from "react-router-dom";
import { GcalAuthentication } from "@geerdsolutions/gcal";

// import Alert from "services/Alert/body";

import Router from "./services/Router";
import Apollo from "./services/Apollo";
import "react-quill/dist/quill.snow.css";
import Admin from "layouts/Admin";

const App = () => {
  return (
    <Fragment>
      <Notification />
      <Routes>
        <Route
          path="/*"
          element={
            <GcalAuthentication>
              <Admin />
            </GcalAuthentication>
          }
        />
      </Routes>
    </Fragment>
  );
};
export default addHocs(App, [Router, Apollo]);
