import React, { Fragment, useRef } from "react";
import Autosuggest from "shared/components/Autosuggest";
import { FieldArray, Field } from "formik";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import CustomInput from "shared/components/CustomInput";
export default function BlocksListForm({
  tags,
  uploadProgress,
  formik,
  handlers,
  types,
}) {
  const {
    values: { tag, tagsToSave, type, file },
  } = formik;
  const { change, setFieldValue } = handlers;
  const fileInput = useRef(null);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <>
      <div>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Create a Block
          </h3>
          <p className="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
            You can create any block type here
          </p>
        </div>
        <div className="mt-6 sm:mt-5">
          <div className="sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Name
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-3">
              <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
                <Field
                  type="text"
                  name="name"
                  placeholder="Block Name"
                  component={CustomInput}
                />
              </div>
            </div>
          </div>

          <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <Listbox value={type} onChange={(value) => change("type")(value)}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2">
                    Type
                  </Listbox.Label>
                  <div className="mt-1 relative sm:mt-0 sm:col-span-3">
                    <div className="max-w-lg relative rounded-md shadow-sm sm:max-w-xs">
                      <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm">
                        <span className="block truncate">
                          {types.filter((item) => item.id === type)[0].name}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          {types.map((type) => (
                            <Listbox.Option
                              key={type.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? "text-white bg-primary-600"
                                    : "text-gray-900",
                                  "cursor-default select-none relative py-2 pl-3 pr-9"
                                )
                              }
                              value={type.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "block truncate"
                                    )}
                                  >
                                    {type.name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? "text-white"
                                          : "text-primary-600",
                                        "absolute inset-y-0 right-0 flex items-center pr-4"
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {(type === "image" || type === "audio") && (
            <div className="mt-6 sm:mt-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="name"
                className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
              >
                Upload
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-3">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <div
                      type="text"
                      className="focus:ring-primary-500 focus:border-primary-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                    >
                      {file ? file.name : "Choose file..."}
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => fileInput.current.click()}
                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500"
                  >
                    <span>Browse</span>
                  </button>
                  <input
                    type="file"
                    accept="audio/mpeg, audio/mp4, image/png, image/jpeg"
                    onChange={(e) => setFieldValue("file", e.target.files[0])}
                    hidden
                    ref={fileInput}
                  />
                </div>
                {!!uploadProgress && (
                  <div className="w-full bg-gray-200 mt-2 rounded-full dark:bg-gray-700">
                    <div
                      className="bg-primary-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                      style={{ width: `${uploadProgress}%` }}
                    >
                      {uploadProgress}%
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="my-6 sm:mt-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="group"
              className="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
            >
              Tags
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-3">
              <FieldArray
                name="tagsToSave"
                render={(arrayHelpers) => (
                  <div>
                    <div className="max-w-lg rounded-md sm:max-w-xs">
                      <Autosuggest
                        data={tags}
                        getOptionLabel={(option) => option.label}
                        onSuggestionSelected={(e, { suggestion }) => {
                          arrayHelpers.push({
                            id: suggestion.id,
                            label: suggestion.label,
                          });
                          change("tag")("");
                        }}
                        {...{
                          placeholder: "Search tag...",
                          value: tag,
                          onChange: (event, { newValue }) =>
                            change("tag")(newValue),
                        }}
                      />
                    </div>{" "}
                    <div className="sm:rounded-md divide-y flex flex-wrap space-x-2 divide-gray-200 mt-3">
                      {tagsToSave.map((tag, index) => (
                        <span
                          key={tag.id}
                          className="inline-flex mt-1 rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-primary-100 text-primary-700"
                        >
                          {tag.label}
                          <button
                            type="button"
                            className="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-primary-400 hover:bg-primary-200 hover:text-primary-500 focus:outline-none focus:bg-primary-500 focus:text-white"
                          >
                            <span className="sr-only">Remove large option</span>
                            <svg
                              className="h-2 w-2"
                              stroke="currentColor"
                              fill="none"
                              viewBox="0 0 8 8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M1 1l6 6m0-6L1 7"
                              />
                            </svg>
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 border-t border-gray-200 pt-5">
        <div className="flex justify-end">
          <span className="ml-3 inline-flex rounded-md shadow-sm">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 focus:shadow-outline-primary active:bg-primary-700 transition duration-150 ease-in-out"
            >
              Save
            </button>
          </span>
        </div>
      </div>
    </>
  );
}
