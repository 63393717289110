import { useMutation } from "services/Client";
import get from "lodash/get";
import { QUESTION_OPTION_GET_MANY } from "services/Client/schemas/queries/questionOption";

import { useNotification } from "hooks";

export default function useDeleteQuestionOption(refetch) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "questionOption.delete",
    update: ({ data: { delete_question_option_by_pk } }, cache) => {
      const fillBlankId = get(delete_question_option_by_pk, "fillBlankId");

      const prevData = cache.readQuery({
        query: QUESTION_OPTION_GET_MANY,
        variables: {
          where: { fillBlankId: { _eq: fillBlankId } },
          order_by: { order: "asc" },
        },
      });

      const newData = prevData.question_option.filter(
        (question_option) =>
          question_option.id !== delete_question_option_by_pk.id
      );
      const data = {
        question_option: [...newData],
      };
      cache.writeQuery({
        query: QUESTION_OPTION_GET_MANY,
        data,
        variables: {
          where: { fillBlankId: { _eq: fillBlankId } },
          order_by: { order: "asc" },
        },
      });
      refetch()
    },
    onCompleted: () => {
      notification.success({
        message: "Delete Successful",
      });
    },
  });
  return mutate;
}
