import React from "react";
import get from "lodash/get";
import { object, string } from "yup";
import { Formik, Form } from "formik";
import { useQuery, useMutation } from "services/Client";
import { useParams, useNavigate } from "react-router-dom";
import { useNotification } from "hooks";
import View from "./view";
export default function All() {
  const { id } = useParams();
  const notification = useNotification();
  const navigate = useNavigate();
  const { data } = useQuery({
    event: "text.get.one",
    variables: {
      id,
    },
    skip: !id,
  });
  const text = get(data, "text_by_pk", { title: "", content: "" });

  const { mutate: updateText } = useMutation({
    event: "text.update",
    onCompleted: () => {
      notification.success({
        message: "Text Saved",
      });
    },
  });

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        title: text.title,
        content: text.content,
      }}
      onSubmit={(values) =>
        updateText({
          variables: {
            _set: {
              ...values,
            },
            pk_columns: { id },
          },
        })
      }
      validationSchema={object({
        title: string().required("error"),
        content: string().min(5, "error").required("error"),
      })}
    >
      {({ handleChange, values: { content } }) => (
        <Form>
          <View
            onCancel={() => navigate(-1)}
            onChange={handleChange}
            content={content}
          />
        </Form>
      )}
    </Formik>
  );
}
