import React, { useState } from "react";
import { useMutation } from "services/Client";
import get from "lodash/get";
import withAlert from "services/Alert";
import View from "./view";

const forEachAsync = async (array, callback) => {
  const promises = [];

  array.forEach((element) => {
    const promise = new Promise(async (resolve, reject) => {
      const result = await callback(element);
      resolve(result);
    });
    promises.push(promise);
  });

  return Promise.all(promises);
};

function Upload({ alert }) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const handleUpload = async (mutate) => {
    
  };

  const handleFile = (files) => {
    setFiles((prevState) => [...prevState, ...files]);
  };

  const handleRemoveFile = (index) => {
    var array = [...files]; // make a separate copy of the array
    array.splice(index, 1)
    setFiles(array);
  };

  // const { mutate } = useMutation({ event: "user.activity.upload" });

  return (
    <View
      handleFile={handleFile}
      handleRemoveFile={handleRemoveFile}
      files={files}
      uploadedFiles={[]}
      handleUpload={() => handleUpload()}
      loading={loading}
    />
  );
}

export default withAlert(Upload);
