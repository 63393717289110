import React, { useState } from "react";
import AddButton from "./AddButton";
export default function SmartContainer({
  order,
  activityId,
  blockToDelete,
  insertStaticBlock
}) {
  const [isHalf, setIsHalf] = useState(true);

  return (
    <>
      <div className={`flex rounded-lg ${isHalf ? "w-1/2" : "w-full"} `}>
        <div className="px-1 py-2 rounded-lg w-full">
          <div className="border-gray-200 rounded-lg flex flex-col h-96 border-2 border-dashed ">
            <div className=" w-full   bg-white   ">
              <button
                className={`py-4 w-1/2 ${isHalf ? "" : "bg-blue-100"}`}
                onClick={() => setIsHalf(false)}
              >
                Full
              </button>
              <button
                className={`py-4 w-1/2 ${!isHalf ? "" : "bg-blue-100"}`}
                onClick={() => setIsHalf(true)}
              >
                Half
              </button>
            </div>
            <div className="flex-1 flex flex-col bg-white justify-center">
              <AddButton
                blockToDelete={blockToDelete}
                activityId={activityId}
                size={isHalf ? "h" : "f"}
                order={order}
                submitStaticBlock={insertStaticBlock}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
