import { gql } from "@apollo/client";

export const LEVEL_FIELDS = gql`
  fragment LEVEL_FIELDS on level {
    id
    name
    order
    passingScore
    overview
  }
`;
