import React, { useState } from "react";
import RichTextEditor from "shared/components/RichTextEditor";

export default function TextEditor() {
  const [value, setvalue] = useState("")

  return (
    <div className="rounded-lg shadow-lg p-3 overflow-auto max-h-screen">
      <RichTextEditor
        value={value}
        onChange={setvalue}
      />
    </div>
  );
}
