import { useMutation } from "services/Client";
import { useNotification } from "hooks";
import { LESSON_GET_MANY } from "services/Client/schemas/queries/lesson";
export default function useCreateLesson(id) {
  const notification = useNotification();
  const { mutate } = useMutation({
    event: "lesson.create",
    update: ({ data: { insert_lesson_one } }, cache) => {
      const prevData = cache.readQuery({
        query: LESSON_GET_MANY,
        variables: {
          where: { unitId: { _eq: id } },
        },
      });
      const data = {
        lesson: [insert_lesson_one, ...prevData.lesson],
      };
      cache.writeQuery({
        query: LESSON_GET_MANY,
        data,
        variables: {
          where: { unitId: { _eq: id } },
        },
      });
    },
    onCompleted: () => {
      notification.success({
        message: "Lesson Saved",
      });
    },
  });
  return mutate;
}
